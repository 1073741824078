import { z } from 'zod';
import {
  ZCohortFilter,
  ZMonthOfBirthFilter,
  ZPlannerPatient,
  ZSubCohortFilter,
  ZTargetAreaFilter,
  ZTargetAreaStateFilter,
} from './planner';

/* API - base type for export and list requests */

const ZPatientListRequestBase = z
  .object({
    monthsOfBirth: ZMonthOfBirthFilter,
    targetAreaStates: ZTargetAreaStateFilter,
    targetArea: ZTargetAreaFilter,
    cohort: ZCohortFilter,
    subCohort: ZSubCohortFilter,
  })
  .strict();

export type IPatientListRequestBase = z.infer<typeof ZPatientListRequestBase>;

/* API - export patient request */

export const ZExportPatientListRequest = ZPatientListRequestBase;

export type IExportPatientListRequest = z.infer<
  typeof ZExportPatientListRequest
>;

/* API - get patient list request */

export const ZGetPatientListRequest = ZPatientListRequestBase.extend({
  page: z.number().min(0).default(0),
}).strict();

export type IGetPatientListRequest = z.infer<typeof ZGetPatientListRequest>;

/* API - responses */

export const ZGetPatientListResponseMeta = z.object({
  total: z.number(),
  offset: z.number(),
  limit: z.number(),
  total_pages: z.number(),
});

export type IGetPatientListResponseMeta = z.infer<
  typeof ZGetPatientListResponseMeta
>;

export const ZGetPatientListResponse = z.object({
  meta: ZGetPatientListResponseMeta,
  result: z.array(ZPlannerPatient),
});

export type IGetPatientListResponse = z.infer<typeof ZGetPatientListResponse>;

export const ZExportListResponse = z.object({
  statusCode: z.number(),
  headers: z
    .object({
      'Content-Type': z.literal('text/csv'),
      'Content-Disposition': z.string(),
      'Access-Control-Expose-Headers': z.literal('Content-Disposition'),
    })
    .optional(),
  body: z.string(),
});

export type IExportListResponse = z.infer<typeof ZExportListResponse>;

export type ICsvExportListData = {
  'Patient name': string;
  'NHS number': string;
  'Date of birth': string;
  'UCLP risk': string;
};

export type EPlannerModuleMatcherMode = 'apply' | 'invert' | 'ignore';

export const patientFilteringCriteria = {
  hasBeenOnboarded: 'hasBeenOnboarded',
  isCurrentlyActive: 'isCurrentlyActive',
  hasCommissionedTargetArea: 'hasCommissionedTargetArea',
  isSuveraEligible: 'isSuveraEligible',
  isPracticeEligible: 'isPracticeEligible',
  isTargetComplete: 'isTargetComplete',
  hasHadReviewThisYear: 'hasHadReviewThisYear',
  hasAnIncompletePatientTask: 'hasAnIncompletePatientTask',
} as const;
