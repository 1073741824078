import { Skeleton, Stack, Typography } from '@mui/material';
import { ESubCohort, TargetAreaStateFilter } from '@suvera/api-types';

import { usePlannerParams } from '../hooks/usePlannerParams';
import { useGetPatientList } from '../requests/useGetPatientList';

const SubcohortTabLabel = ({
  label,
  isLoading,
  count,
}: {
  label: string;
  isLoading: boolean;
  count: number | undefined;
}) => (
  <Stack direction="row" spacing="6px" alignItems="baseline">
    <Typography variant="body2" fontWeight="inherit" color="inherit">
      {label}
    </Typography>

    {isLoading ? (
      <Skeleton variant="text" sx={{ fontSize: '12px', width: '20px' }} />
    ) : (
      <Typography
        variant="body2"
        color="colors.greyDark"
        style={{ fontSize: '12px' }}
      >
        {` (${count})`}
      </Typography>
    )}
  </Stack>
);

export const TargetStateTabLabel = ({
  label,
  targetAreaStates,
}: {
  label: string;
  targetAreaStates: TargetAreaStateFilter;
}) => {
  const plannerParams = usePlannerParams();
  const { isLoading, data } = useGetPatientList({
    ...plannerParams,
    targetAreaStates,
  });

  return (
    <SubcohortTabLabel
      label={label}
      isLoading={isLoading}
      count={data?.meta.total}
    />
  );
};

export const ActiveTabLabel = ({
  label,
  subCohort,
}: {
  label: string;
  subCohort: ESubCohort;
}) => {
  const plannerParams = usePlannerParams();
  const { isLoading, data } = useGetPatientList({
    ...plannerParams,
    cohort: 'active',
    subCohort,
  });

  return (
    <SubcohortTabLabel
      label={label}
      isLoading={isLoading}
      count={data?.meta.total}
    />
  );
};
