import { useAuth0 } from '@auth0/auth0-react';
import { setUser as setSentryUser } from '@sentry/react';
import { Avo } from 'infrastructure/analytics';
import { useEffect } from 'react';
import { useGetAuthenticatedPartner } from './requests';

const useUserIdentified = () => {
  const user = useGetAuthenticatedPartner().data;
  useEffect(() => {
    if (user) {
      Avo.userIdentified({ userId_: user.id });
      setSentryUser({
        id: user.id,
      });
    }
  }, [user]);
};

const UserListenerWithAuth = () => {
  useUserIdentified();
  return null;
};

export const UserListener = () => {
  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) return null;
  return <UserListenerWithAuth />;
};
