import z from 'zod';
import { ZObjectId } from './objectid';
import { ZBaseTask } from './task';

export const ZReferralTask = ZBaseTask.omit({
  partner: true,
  task_type: true,
}).extend({
  partner: ZObjectId,
  task_type: z.literal('gp_referral'),
});

export type IReferralTask = z.infer<typeof ZReferralTask>;
