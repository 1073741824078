export const PLANNER_FEEDBACK_FORM_URL =
  'https://dqmp1l111tv.typeform.com/to/EjrS9Yib';

export const PLANNER_REGISTRATION_FORM_URL =
  'https://dqmp1l111tv.typeform.com/to/htYnkXyp';

export const PRACTICE_SUPPORT_NUMBER = '020 3882 5131';

export const PRACTICE_SUPPORT_NUMBER_HREF = 'tel:+442038825131';

export const USER_GUIDE_URL =
  'https://suvera.notion.site/A-guide-to-Planner-0ed4996d92c248beac55ccb3b3e6004e';

export const PRIVACY_NOTICE_URL = 'https://www.suvera.com/privacy-notice';
