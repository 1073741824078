import { Info, Close } from '@mui/icons-material';
import { Snackbar, Alert, Button } from '@mui/material';
import { FC } from 'react';

type ErrorSnackbarProps = {
  onClose: () => void;
  isOpen: boolean;
  message: string;
};

const ErrorSnackbar: FC<ErrorSnackbarProps> = ({
  onClose,
  isOpen,
  message,
}) => {
  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Alert
        severity="error"
        variant="filled"
        icon={<Info fontSize="inherit" />}
        action={
          <Button
            onClick={onClose}
            color="inherit"
            size="small"
            startIcon={<Close fontSize="inherit" height="20px" />}
            sx={{
              '.MuiButton-startIcon>*:nth-of-type(1)': {
                fontSize: '20px',
              },
              fontWeight: 500,
            }}
          >
            Close
          </Button>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
