import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect } from 'react';

import { Avo } from 'infrastructure/analytics';
import { nhsNumberHelperText } from 'pages/CreateReferralPage/utils/nhsNumberHelperText';
import { nhsNumberSchema } from 'schemas/nhsNumbers';

import ValidNhsNumberOption from './ValidNhsNumberOption';

type Props = { searchValue: string; isLoading: boolean };

function ErrorMessageOption({ errorText }: { errorText: string }) {
  const errorMessage = nhsNumberHelperText(errorText);

  useEffect(() => {
    Avo.findAPatientValidationErrorMessageSeen({ errorMessage });
  }, [errorMessage]);

  return (
    <Typography variant="body1" paddingX="12px">
      {errorMessage}
    </Typography>
  );
}

export default function AutocompleteOption({ searchValue, isLoading }: Props) {
  const { error, success, data } = nhsNumberSchema.safeParse(searchValue);

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          paddingY: '8px',
        }}
      >
        <CircularProgress size={24} />
      </Box>
    );

  if (!success) {
    const errorText = (JSON.parse(error.message) as { message: string }[])[0]
      .message;

    return <ErrorMessageOption errorText={errorText} />;
  }

  return <ValidNhsNumberOption nhsNumber={data} />;
}
