import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import {
  IGetPatientListResponseMeta,
  IPlannerPatient,
} from '@suvera/api-types';
import { useSearchParams } from 'react-router-dom';
import React from 'react';

import PatientRow from './PatientRow';
import PlannerHeader from './PlannerHeader';
import PlannerListHeader from './PlannerListHeader';
import { PlannerPagination } from './PlannerPagination';
import SubcohortTabs from './SubcohortTabs';

export { subTabStyles } from './PlannerHeader';

type Props = {
  meta: IGetPatientListResponseMeta;
  patients: IPlannerPatient[];
};

const plannerContainerStyle = {
  borderColor: 'colors.grey',
  borderWidth: '1px',
  borderStyle: 'solid',
  '.MuiBox-root:nth-of-type(odd)': {
    bgcolor: 'colors.white',
  },
  borderRadius: '8px',
  overflow: 'hidden',
};

const PlannerListWrapper = ({
  children,
  exportIsDisabled,
}: {
  children: React.ReactNode;
  exportIsDisabled: boolean;
}) => (
  <Stack spacing="16px" flex={1} width="100%">
    <PlannerHeader exportIsDisabled={exportIsDisabled} />
    {children}
  </Stack>
);

export const PlannerListLoadingState = () => (
  <PlannerListWrapper exportIsDisabled>
    <Stack
      spacing="8px"
      sx={{
        paddingX: '16px',
        height: '100vh',
      }}
    >
      <PlannerListHeader />

      <Box sx={plannerContainerStyle}>
        <SubcohortTabs />

        <Box
          minHeight="calc(100vh - 320px)"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="colors.white"
        >
          <CircularProgress />
        </Box>
      </Box>
    </Stack>
  </PlannerListWrapper>
);

const PlannerList = ({ patients, meta }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isBannerVisible =
    sessionStorage.getItem('plannerLastUpdatedBannerDismissed') !== 'true';

  if (patients.length === 0) {
    return (
      <PlannerListWrapper exportIsDisabled={meta.total === 0}>
        <Stack spacing="8px" sx={{ paddingX: '16px', height: '100vh' }}>
          <PlannerListHeader />
          <Box sx={plannerContainerStyle}>
            <SubcohortTabs />

            <Box
              minHeight="calc(100vh - 320px)"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              bgcolor="colors.white"
            >
              <Typography variant="h3">No patients</Typography>

              <Typography>
                Try changing the filters you&apos;ve selected
              </Typography>
            </Box>
          </Box>
        </Stack>
      </PlannerListWrapper>
    );
  }

  return (
    <PlannerListWrapper exportIsDisabled={meta.total === 0}>
      <Stack>
        <Box
          sx={{
            height: isBannerVisible
              ? 'calc(100vh - 280px)'
              : 'calc(100vh - 240px)',
            overflowY: 'auto',
          }}
        >
          <Stack spacing="8px" sx={{ paddingX: '16px' }}>
            <PlannerListHeader />

            <Box sx={plannerContainerStyle}>
              <SubcohortTabs />

              {patients.map((patient) => (
                <PatientRow key={patient._id} {...patient} />
              ))}
            </Box>
          </Stack>
        </Box>

        <PlannerPagination
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          meta={meta}
        />
      </Stack>
    </PlannerListWrapper>
  );
};

export default PlannerList;
