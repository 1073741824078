import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { IPartnerPatientDetails } from '@suvera/api-types';
import { format } from 'date-fns/fp';
import { truncate } from 'lodash';
import { PropsWithChildren, useState } from 'react';

import { BoxWithGreyBorder } from 'components/FullScreenBoxWithGreyBorder';
import { Avo } from 'infrastructure/analytics';
import { addSpacesToNhsNumber } from 'utils/addSpacesToNhsNumber';
import { CopyButton } from './CopyButton';
import { EhrSync } from './EhrSync';
import UpcomingAppointments from './UpcomingAppointments';

const formatDateDDMMYYYY = format('dd/MM/yyyy');
const formatDate = format('d MMMM yyyy');

const OutlinedGreyFullScreenBox = ({ children }: PropsWithChildren<object>) => {
  return (
    <Box
      sx={{
        padding: '8px',
        border: 'solid 1px',
        borderColor: 'colors.grey',
        backgroundColor: 'colors.greyLightest',
        borderRadius: '4px',
      }}
    >
      {children}
    </Box>
  );
};

const SMSDialog = ({
  open,
  handleClose,
  dateOfLastSms,
  lastSmsContent,
}: {
  open: boolean;
  handleClose: () => void;
  dateOfLastSms: Date | null;
  lastSmsContent: string | null;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      data-testid="last_sms_modal"
      maxWidth="sm"
      fullWidth
    >
      <Stack direction="row" justifyContent="space-between">
        <DialogTitle component="h1" variant="h2">
          Last SMS sent from Suvera
        </DialogTitle>

        <Box paddingX="24px" paddingY="16px">
          <IconButton
            onClick={handleClose}
            size="medium"
            aria-label="Close modal"
            sx={{ color: 'colors.black' }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </Box>
      </Stack>
      <DialogContent sx={{ paddingTop: '0px' }}>
        {dateOfLastSms && lastSmsContent ? (
          <>
            <Typography>SMS sent: {formatDate(dateOfLastSms)}</Typography>
            <Box sx={{ marginTop: '16px', paddingX: '100px' }}>
              <Box
                sx={{
                  backgroundColor: 'colors.greyLight',
                  borderRadius: '16px 16px 16px 0px',
                  padding: '16px',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}
              >
                <Typography>{lastSmsContent}</Typography>
              </Box>
            </Box>
          </>
        ) : (
          <OutlinedGreyFullScreenBox>
            <Typography>No last SMS</Typography>
          </OutlinedGreyFullScreenBox>
        )}
      </DialogContent>
    </Dialog>
  );
};

type Props = {
  patient: IPartnerPatientDetails;
};

const formatPatientName = (patient: IPartnerPatientDetails) =>
  patient.name.title
    ? `${patient.name.title} ${patient.name.first} ${patient.name.last}`
    : `${patient.name.first} ${patient.name.last}`;

const PatientViewDetails = ({ patient }: Props) => {
  const [lastSmsModal, setLastSmsModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    Avo.patientViewLastSmsModalOpened();
    setLastSmsModal(true);
  };

  const handleModalClose = () => {
    Avo.patientViewLastSmsModalClosed();
    setLastSmsModal(false);
  };

  const showEhrSync = patient.ehrSync.isEnabled;

  const fullName = `${patient.name.first} ${patient.name.last}`;

  return (
    <>
      <Box flex={1}>
        <BoxWithGreyBorder>
          <Stack spacing="16px">
            <Stack spacing="12px">
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Typography variant="h3" component="h1">
                  {formatPatientName(patient)}
                </Typography>

                <CopyButton
                  copyText={fullName}
                  ariaLabel="Copy patient name"
                  onClick={() => Avo.copyPatientNameButtonClicked()}
                />
              </Stack>

              <Stack spacing="8px" direction="row">
                <Stack spacing="2px" data-testid="nhs_number">
                  <Typography variant="body2">NHS number</Typography>

                  <Chip
                    size="small"
                    variant="outlined"
                    label={addSpacesToNhsNumber(patient.nhsNumber)}
                  />
                </Stack>

                <Stack spacing="2px" data-testid="date_of_birth">
                  <Typography variant="body2">Date of birth</Typography>

                  <Chip
                    size="small"
                    variant="outlined"
                    label={
                      patient.dateOfBirth
                        ? formatDateDDMMYYYY(patient.dateOfBirth)
                        : '-'
                    }
                  />
                </Stack>

                <Stack spacing="2px" data-testid="uclp_risk_score">
                  <Typography variant="body2">Risk score</Typography>

                  <Chip
                    size="small"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    label={patient.uclpRisk ?? '-'}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Box display="flex" flexDirection="row">
              <Stack spacing="2px" flex={1} data-testid="last_sms">
                <Typography variant="body2">
                  Last SMS sent from Suvera
                </Typography>

                <OutlinedGreyFullScreenBox>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent={'center'}
                  >
                    {patient.dateOfLastSms ? (
                      <>
                        <Stack>
                          <Typography
                            sx={{ fontWeight: 'bold' }}
                            component="span"
                            variant="body2"
                          >
                            {formatDate(patient.dateOfLastSms)}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ wordBreak: 'break-word' }}
                          >
                            {truncate(`${patient.lastSmsContent}`, {
                              length: 80,
                            })}
                          </Typography>
                        </Stack>

                        <Button onClick={handleModalOpen}>View</Button>
                      </>
                    ) : (
                      <Typography variant="body2">No last SMS</Typography>
                    )}
                  </Box>
                </OutlinedGreyFullScreenBox>
              </Stack>
            </Box>

            <Stack spacing="2px" data-testid="upcoming_appointments">
              <Typography variant="body2">Upcoming appointments</Typography>

              <UpcomingAppointments patientId={patient.id} />
            </Stack>

            {showEhrSync && (
              <Stack spacing="2px" data-testid="ehr_sync">
                <EhrSync patient={patient} />
              </Stack>
            )}
          </Stack>
        </BoxWithGreyBorder>
      </Box>

      <SMSDialog
        open={lastSmsModal}
        handleClose={handleModalClose}
        dateOfLastSms={patient.dateOfLastSms}
        lastSmsContent={patient.lastSmsContent}
      />
    </>
  );
};

export default PatientViewDetails;
