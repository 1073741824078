import { Snackbar, Alert, Typography, Stack } from '@mui/material';
import { FC } from 'react';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useTheme } from '@mui/material/styles';

type PlannerSnackbarProps = {
  onClose: () => void;
  isOpen: boolean;
  message: string;
  isError: boolean;
};

const PlannerSnackbar: FC<PlannerSnackbarProps> = ({
  onClose,
  isOpen,
  message,
  isError,
}) => {
  const theme = useTheme();

  const errorStyling = {
    borderLeft: `8px solid  ${theme.palette.colors.red}`,
    backgroundColor: theme.palette.colors.redLight,
  };
  const successStyling = {
    borderLeft: `8px solid ${theme.palette.colors.green}`,
    backgroundColor: theme.palette.colors.greenLight,
  };
  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        icon={false}
        sx={{
          marginTop: '55px',
          color: 'colors.black',
          width: '492px',
          padding: '4px 16px',
          ...(isError ? { ...errorStyling } : { ...successStyling }),
        }}
      >
        <Stack gap={0.5}>
          <Stack direction="row" alignItems="center" gap={1}>
            {isError ? (
              <ErrorRoundedIcon
                sx={{
                  color: 'colors.red',
                  verticalAlign: 'bottom',
                }}
              />
            ) : (
              <CheckCircleRoundedIcon
                sx={{
                  color: 'colors.green',
                  verticalAlign: 'bottom',
                }}
              />
            )}
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, lineHeight: 1.5 }}
            >
              {isError ? 'Error' : 'Success'}
            </Typography>
          </Stack>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 500, lineHeight: 1.5 }}
          >
            {message}
          </Typography>
        </Stack>
      </Alert>
    </Snackbar>
  );
};

export default PlannerSnackbar;
