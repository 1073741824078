import { z } from 'zod';

export const ZBaseEhrHistory = z.object({
  datetime: z.date(),
});

export const ZEhrHistory = z.union([
  ZBaseEhrHistory.extend({
    status: z.literal('pending_grace_period'),
    willSyncAfter: z.date(),
  }),
  ZBaseEhrHistory.extend({
    status: z.literal('pending'),
  }),
  ZBaseEhrHistory.extend({
    status: z.literal('success'),
  }),
  ZBaseEhrHistory.extend({
    status: z.literal('error'),
    error_message: z.string(),
  }),
  ZBaseEhrHistory.extend({
    status: z.literal('failure'),
    failure_message: z.string().optional(),
  }),
  ZBaseEhrHistory.extend({
    status: z.literal('manually_added'),
    suverian: z.string(),
  }),
]);

export type EhrHistory = z.infer<typeof ZEhrHistory>;
