import { Stack } from '@mui/material';

import { Suspense, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import LoadingState from 'components/LoadingState';
import Page from 'components/Page';
import { useGetPatientByNhsNumber } from 'pages/CreateReferralPage/requests/useGetPatientByNhsNumber';
import { sanitiseNhsNumber } from 'schemas/nhsNumbers';

import { GreyFullScreenBox } from 'components/FullScreenBoxWithGreyBorder';
import PatientViewDetails from './components/PatientViewDetails';
import PatientViewHeader from './components/PatientViewHeader';
import PatientViewTargetTabs from './components/PatientViewTargetTabs';
import UnknownPatientView from './components/UnknownPatientView';

const PatientViewPage = () => {
  const nhsNumber = useParams().nhsNumber || '';

  const {
    refetch,
    data: patient,
    isLoading,
  } = useGetPatientByNhsNumber(sanitiseNhsNumber(nhsNumber));

  // useGetPatientByNhsNumber is off on mount by default so force the fetch here
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetch();
  }, [refetch]);

  if (isLoading)
    return (
      <Page>
        <LoadingState />
      </Page>
    );

  if (!patient) {
    return (
      <Page>
        <UnknownPatientView />
      </Page>
    );
  }

  return (
    <Suspense fallback={<LoadingState />}>
      <Page>
        <GreyFullScreenBox>
          <PatientViewHeader patient={patient} />

          <Stack
            direction="row"
            sx={{ justifyContent: 'center' }}
            spacing="16px"
            height="fit-content"
          >
            <PatientViewDetails patient={patient} />

            <PatientViewTargetTabs patient={patient} />
          </Stack>
        </GreyFullScreenBox>
      </Page>
    </Suspense>
  );
};

export default PatientViewPage;
