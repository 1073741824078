import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { IGetPatientListResponseMeta } from '@suvera/api-types';
import { Avo } from 'infrastructure/analytics';
import { SetURLSearchParams } from 'react-router-dom';

type Props = {
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  meta: IGetPatientListResponseMeta;
};

export const PlannerPagination = ({
  searchParams,
  setSearchParams,
  meta,
}: Props) => {
  const currentPage = Number.parseInt(searchParams.get('page') ?? '', 10);

  const recordCountStart = currentPage * meta.limit + 1;
  const recordCountEnd = Math.min(
    currentPage * meta.limit + meta.limit,
    meta.total,
  );

  const handlePageChange = (pageChange: number) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', (currentPage + pageChange).toString());
    setSearchParams(updatedSearchParams.toString());
  };

  const handlePrevPageClick = () => {
    handlePageChange(-1);
    Avo.plannerPreviousPageButtonClicked();
  };

  const handleNextPageClick = () => {
    handlePageChange(1);
    Avo.plannerNextPageButtonClicked();
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      sx={{ paddingX: '16px', paddingY: '8px' }}
    >
      <Typography>
        {recordCountStart} - {recordCountEnd} of {meta.total}
      </Typography>
      <IconButton
        aria-label="previous page"
        color="primary"
        onClick={handlePrevPageClick}
        disabled={currentPage === 0}
      >
        <ChevronLeft />
      </IconButton>
      <IconButton
        aria-label="next page"
        color="primary"
        onClick={handleNextPageClick}
        disabled={currentPage >= meta.total / meta.limit - 1}
      >
        <ChevronRight />
      </IconButton>
    </Box>
  );
};
