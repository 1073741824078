import z from 'zod';
import { ZName } from './name';
import { ZObjectId } from './objectid';

const ZSuverianEmail = z.object({
  suvera: z.string().email(),
  alternative: z.string().email().optional(),
});
export type ISuverianEmail = z.infer<typeof ZSuverianEmail>;

export const ZSuverian = z.object({
  _id: ZObjectId,
  name: ZName,
  email: ZSuverianEmail.optional(),
  team: z.string().optional(),
  role: z.string().optional(),
  active: z.boolean().optional(),
  acuity_calendar_id: z.string().optional(),
});

export type ISuverian = z.infer<typeof ZSuverian>;
