// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

// Check criteria: https://www.datadictionary.nhs.uk/attributes/nhs_number.html

import z from 'zod';

// @ts-ignore
const createMod11Checkdigit = (input) => {
  const stringInput = input;
  let sum = 0;
  [...stringInput].forEach((value, index) => {
    sum += Number.parseInt(value, 10) * (10 - index);
  });
  const sumMod11 = sum % 11;
  const elevenMinusRemainder = 11 - sumMod11;
  if (elevenMinusRemainder === 11) return '0';
  if (elevenMinusRemainder === 10) return '-';
  return elevenMinusRemainder.toString();
};

// @ts-ignore
export const isValidMod11Number = (input) => {
  const stringInput = String(input);
  const checkDigitIndex = stringInput.length - 1;
  const checkDigit = stringInput.slice(checkDigitIndex);
  const inputWithoutCheckdigit = stringInput.slice(
    0,
    Math.max(0, checkDigitIndex),
  );
  const regeneratedCheckdigit = createMod11Checkdigit(inputWithoutCheckdigit);
  return checkDigit === regeneratedCheckdigit;
};

export const ZNHSNumberValidator = z
  .string()
  .length(10)
  .refine(isValidMod11Number);
