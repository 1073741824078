import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

export function GreyFullScreenBox({ children }: PropsWithChildren<object>) {
  return (
    <Box
      sx={{
        bgcolor: 'colors.greyLightest',
        paddingY: '16px',
        paddingX: '16px',
        minHeight: 'calc(100vh - 48px)',
      }}
    >
      {children}
    </Box>
  );
}

export function BoxWithGreyBorder({
  maxWidth,
  children,
}: {
  maxWidth?: string;
  paddingRight?: string;
} & PropsWithChildren<object>) {
  const getMaxWidth = () => {
    if (maxWidth === 'md') {
      return '426px';
    }
    if (maxWidth === 'lg') {
      return '548px';
    }
    return 'unset';
  };

  return (
    <Box
      sx={{
        marginX: 'auto',
        paddingX: '16px',
        paddingY: '16px',
        width: '100%',
        maxWidth: getMaxWidth(),
        borderColor: 'colors.grey',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '8px',
        bgcolor: 'colors.white',
      }}
    >
      {children}
    </Box>
  );
}

export function FullScreenBoxWithGreyBorder({
  children,
}: PropsWithChildren<object>) {
  return (
    <GreyFullScreenBox>
      <BoxWithGreyBorder maxWidth="lg">{children}</BoxWithGreyBorder>
    </GreyFullScreenBox>
  );
}
