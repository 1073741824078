import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ROUTES, linkToPatientView } from 'App/routes';
import { Avo } from 'infrastructure/analytics';
import { useGetPatientByNhsNumber } from 'pages/CreateReferralPage/requests/useGetPatientByNhsNumber';
import { sanitiseNhsNumber } from 'schemas/nhsNumbers';

function ExistingPatientOption({
  nhsNumber,
  patientName,
}: {
  nhsNumber: string;
  patientName: string;
}) {
  useEffect(() => {
    Avo.findAPatientExistingPatientDetailsViewed();
  }, [nhsNumber]);

  return (
    <Link
      to={linkToPatientView(nhsNumber)}
      style={{ textDecoration: 'none', color: 'inherit' }}
      onClick={() => {
        Avo.findAPatientPatientViewButtonClicked();
      }}
    >
      <Stack
        paddingY="8px"
        paddingX="12px"
        sx={{ '&:hover': { backgroundColor: 'colors.greyLightest' } }}
      >
        <Typography variant="body2" color="colors.greyDark">
          {nhsNumber}
        </Typography>
        <Typography variant="body1">{patientName}</Typography>
      </Stack>
    </Link>
  );
}

function NewPatientOption({ nhsNumber }: { nhsNumber: string }) {
  useEffect(() => {
    Avo.findAPatientNewPatientDetailsViewed();
  }, [nhsNumber]);

  return (
    <Stack paddingTop="8px" paddingX="12px">
      <Typography variant="body1">
        We couldn&apos;t find this NHS number
      </Typography>

      <Link
        to={`${ROUTES.create_referral}?nhsNumber=${sanitiseNhsNumber(nhsNumber)}`}
        onClick={() => {
          Avo.findAPatientCreateReferralButtonClicked();
        }}
        style={{ alignSelf: 'end' }}
      >
        <Button variant="text" sx={{ width: 'fit-content' }}>
          Create a referral
        </Button>
      </Link>
    </Stack>
  );
}

type Props = { nhsNumber: string };

export default function ValidNhsNumberOption({ nhsNumber }: Props) {
  const {
    refetch,
    data: patient,
    isLoading,
    isFetched,
  } = useGetPatientByNhsNumber(nhsNumber);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetch();
  }, [refetch]);

  if (isLoading || !isFetched)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          paddingY: '8px',
        }}
      >
        <CircularProgress size={24} />
      </Box>
    );

  if (patient)
    return (
      <ExistingPatientOption
        nhsNumber={nhsNumber}
        patientName={`${patient.name.first} ${patient.name.last}`}
      />
    );

  return <NewPatientOption nhsNumber={nhsNumber} />;
}
