import { z } from 'zod';

export const ZName = z.object({
  title: z.string().trim().optional(),
  first: z.string().trim(),
  last: z.string().trim(),
  preferred: z.string().trim().optional(),
});

export type IName = z.infer<typeof ZName>;
