import SouthIcon from '@mui/icons-material/South';
import { Stack, Typography } from '@mui/material';
import { TARGET_AREA_LABELS } from '@suvera/core-types';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';

import { Avo } from 'infrastructure/analytics';

import { usePlannerParams } from '../hooks/usePlannerParams';
import { useCohortFromParams } from '../hooks/useCohortFromParams';

const UCLP_RISK_URL =
  'https://suvera.notion.site/A-guide-to-Planner-0ed4996d92c248beac55ccb3b3e6004e';

const useHeadingText = () => {
  const cohortTemplate = useCohortFromParams().template;
  const { targetArea } = usePlannerParams();

  const targetAreaPatients = `${TARGET_AREA_LABELS[targetArea]} patients`;

  return capitalize(
    cohortTemplate.replace('<Patients>', targetAreaPatients),
  ).replace('copd', 'COPD');
};

const PlannerListHeader = () => {
  const headingText = useHeadingText();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="body1" fontWeight={600} component="h2">
        {headingText}
      </Typography>

      <Typography variant="body2" color="colors.greyDark">
        This list is sorted by{' '}
        <Typography
          variant="body2"
          component={Link}
          to={UCLP_RISK_URL}
          target="_blank"
          onClick={() => {
            Avo.uclpRiskInformationButtonClicked();
          }}
          sx={{ color: 'colors.blue', textUnderlineOffset: '4px' }}
        >
          UCLP risk
        </Typography>
        , priority high to low
        <SouthIcon
          sx={{
            height: '16px',
            color: 'inherit',
            marginBottom: '-2px',
          }}
        />
      </Typography>
    </Stack>
  );
};

export default PlannerListHeader;
