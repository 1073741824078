import { z } from 'zod';
import { ZObjectId } from './objectid';

export const ZClinicalCommissioningGroup = z.object({
  _id: ZObjectId,
  name: z.string(),
});

export type IClinicalCommissioningGroup = z.infer<
  typeof ZClinicalCommissioningGroup
>;
