import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { activeSubCohorts, TargetAreaStateFilter } from '@suvera/api-types';
import { SyntheticEvent } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Avo } from 'infrastructure/analytics';
import { ActiveSubcohortTabValueType } from 'infrastructure/analytics/Avo';

import { useLocationIncludesYourPatients } from '../hooks/useLocationIncludesYourPatients';
import { ActiveTabLabel, TargetStateTabLabel } from './SubcohortTabLabel';

const targetStateTabs: {
  value: string;
  label: string;
  targetAreaStates: ('incomplete' | 'complete')[];
}[] = [
  {
    value: 'all',
    label: 'All',
    targetAreaStates: [],
  },
  {
    value: 'incomplete',
    label: 'Target area incomplete',
    targetAreaStates: ['incomplete'],
  },
  {
    value: 'complete',
    label: 'Target area complete',
    targetAreaStates: ['complete'],
  },
];

const subGroupTabStyles = {
  color: 'colors.black',
  padding: '8px 0px',
  minHeight: '44px',
  fontWeight: 400,
  marginX: '8px',

  '&.Mui-selected': {
    color: 'colors.black',
    fontWeight: 600,
  },
};

const SubcohortTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const areaStatesParamValues = searchParams.getAll('targetAreaState');
  const subCohortParamValue = searchParams.getAll('subCohort');
  const locationIncludesYourPatients = useLocationIncludesYourPatients();
  const cohortParamValues = searchParams.getAll('cohort');
  const showActiveSubCohortTabs =
    cohortParamValues.length === 1 &&
    cohortParamValues[0] === 'active' &&
    !locationIncludesYourPatients;

  const getDefaultSubCohortTabValue = () => {
    if (showActiveSubCohortTabs) {
      if (subCohortParamValue.length === 0) {
        return 'all-active';
      }
      return subCohortParamValue[0];
    }
    if (areaStatesParamValues.length === 0) {
      return 'all';
    }
    return areaStatesParamValues[0];
  };

  const handleTargetAreaStateChange = (
    event: SyntheticEvent,
    newValue: string,
  ) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (newValue === 'all') {
      updatedSearchParams.delete('targetAreaState');
      updatedSearchParams.delete('subCohort');
    } else {
      updatedSearchParams.set('targetAreaState', newValue);
    }
    updatedSearchParams.set('page', '0');
    setSearchParams(updatedSearchParams.toString());

    Avo.targetAreaStatesFilterUpdated({
      targetAreaStates: updatedSearchParams.getAll(
        'targetAreaState',
      ) as TargetAreaStateFilter,
    });
  };

  const handleChangeSuveraActive = (
    event: SyntheticEvent,
    newValue: string,
  ) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (newValue === 'all-active') {
      updatedSearchParams.delete('targetAreaState');
      updatedSearchParams.delete('subCohort');
    } else {
      updatedSearchParams.set('subCohort', newValue);
    }
    updatedSearchParams.set('page', '0');
    setSearchParams(updatedSearchParams.toString());

    Avo.activeSubcohortTabClicked({
      activeSubcohortTab: newValue as ActiveSubcohortTabValueType,
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'colors.white',
        paddingX: '4px',
        borderBottom: (theme) => `1px solid ${theme.palette.colors.grey}`,
      }}
      data-testid="subgroup-tabs"
    >
      <TabContext value={getDefaultSubCohortTabValue()}>
        {showActiveSubCohortTabs ? (
          <TabList
            sx={{
              minHeight: '40px',
            }}
            onChange={handleChangeSuveraActive}
            aria-label="sub-tabs"
          >
            {Object.values(activeSubCohorts).map(({ value, label }) => (
              <Tab
                data-testid={`${value}-tab`}
                label={<ActiveTabLabel label={label} subCohort={value} />}
                key={value}
                sx={subGroupTabStyles}
                value={value}
              />
            ))}
          </TabList>
        ) : (
          <TabList
            sx={{
              minHeight: '40px',
            }}
            onChange={handleTargetAreaStateChange}
            aria-label="sub-tabs"
          >
            {targetStateTabs.map(({ value, label, targetAreaStates }) => (
              <Tab
                data-testid={`${value}-tab`}
                value={value}
                key={value}
                sx={subGroupTabStyles}
                label={
                  <TargetStateTabLabel
                    label={label}
                    targetAreaStates={targetAreaStates}
                  />
                }
              />
            ))}
          </TabList>
        )}
      </TabContext>
    </Box>
  );
};

export default SubcohortTabs;
