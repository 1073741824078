import { z } from 'zod';
import { ZObjectId } from './objectid';

export const ZPrimaryCareNetwork = z.object({
  _id: ZObjectId,
  name: z.string(),
});
export type IPrimaryCareNetwork = z.infer<typeof ZPrimaryCareNetwork>;

export const ZCreatePrimaryCareNetwork = ZPrimaryCareNetwork.omit({
  _id: true,
}).strict();
export type ICreatePrimaryCareNetwork = z.infer<
  typeof ZCreatePrimaryCareNetwork
>;
