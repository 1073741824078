import { TabContext, TabList } from '@mui/lab';
import { Skeleton, Stack, Tab, Typography } from '@mui/material';
import { ECohort, practiceCohorts, suveraCohorts } from '@suvera/api-types';
import { SyntheticEvent } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Avo } from 'infrastructure/analytics';
import { CohortTabClickedProperties } from 'infrastructure/analytics/Avo';

import { useCohortFromParams } from '../hooks/useCohortFromParams';
import { useLocationIncludesYourPatients } from '../hooks/useLocationIncludesYourPatients';
import { usePlannerParams } from '../hooks/usePlannerParams';
import { useGetPatientList } from '../requests/useGetPatientList';

import ExportListButton from './ExportListButton';
import SuveraOnboardingRulesButton from './SuveraOnboardingRulesButton';

type Props = {
  exportIsDisabled: boolean;
};

export const subTabStyles = {
  fontWeight: 600,
  color: 'colors.greyDark',
  height: '40px',
  minHeight: '40px',
  borderRadius: '4px',
  '&.Mui-selected': {
    color: '#246ccc',
    fontWeight: 600,
    backgroundColor: '#e3f0fd',
  },
  paddingY: '8px',
  paddingX: '12px',
  marginX: '4px',
};

const TabLabel = ({ label, cohort }: { label: string; cohort: ECohort }) => {
  const plannerParams = usePlannerParams();
  const { isLoading, data } = useGetPatientList({
    ...plannerParams,
    cohort,
    subCohort: null,
    targetAreaStates: [],
  });

  return (
    <Stack direction="row" spacing="8px" alignItems="center">
      <Typography variant="body1" fontWeight={600} color="inherit">
        {label}
      </Typography>

      {isLoading ? (
        <Skeleton variant="text" sx={{ fontSize: '14px', width: '24px' }} />
      ) : (
        <Typography variant="body2" color="inherit">
          ({data?.meta.total})
        </Typography>
      )}
    </Stack>
  );
};

const PlannerHeader = ({ exportIsDisabled }: Props) => {
  const locationIncludesYourPatients = useLocationIncludesYourPatients();
  const [searchParams, setSearchParams] = useSearchParams();
  const cohort = useCohortFromParams();
  const params = usePlannerParams();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.delete('subCohort');
    updatedSearchParams.delete('targetAreaState');
    updatedSearchParams.set('cohort', newValue);
    setSearchParams(updatedSearchParams.toString());
  };

  return (
    <Stack
      spacing="16px"
      sx={{
        width: '100%',
        paddingX: '16px',
        paddingTop: '16px',
        bgcolor: 'colors.white',
        boxShadow: '0 4px 4px 0 rgba(15, 27, 56, 0.08)',
      }}
    >
      <Stack
        spacing="16px"
        direction="row"
        alignItems="start"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h3" component="h1">
            {locationIncludesYourPatients ? 'Your patients' : 'Suvera patients'}
          </Typography>

          <Typography variant="body2" color="colors.greyDark">
            {cohort.description}
          </Typography>
        </Stack>

        <Stack direction="row" spacing="8px">
          <SuveraOnboardingRulesButton />

          <ExportListButton disabled={exportIsDisabled} />
        </Stack>
      </Stack>

      <Stack
        spacing="12px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TabContext value={params.cohort}>
          {
            <TabList
              onChange={handleChange}
              aria-label="Navigation tabs"
              sx={{
                minHeight: '40px',
              }}
              TabIndicatorProps={{
                sx: {
                  '&.MuiTabs-indicator': {
                    backgroundColor: 'colors.blue',
                    height: '2px !important',
                  },
                },
              }}
            >
              {Object.values(
                locationIncludesYourPatients ? practiceCohorts : suveraCohorts,
              ).map(({ value, label }) => (
                <Tab
                  label={<TabLabel label={label} cohort={value} />}
                  key={value}
                  sx={subTabStyles}
                  value={value}
                  onClick={() => {
                    Avo.cohortTabClicked({
                      cohortTab: value,
                    } as CohortTabClickedProperties);
                  }}
                />
              ))}
            </TabList>
          }
        </TabContext>
      </Stack>
    </Stack>
  );
};

export default PlannerHeader;
