import { z } from 'zod';
import { ZNHSNumberValidator, ZName } from '@suvera/core-types';

export const ZCreateExistingPatientReferral = z.object({
  note: z.string().optional().default(''),
});

export const ZCreateNewPatientReferral = ZCreateExistingPatientReferral.extend({
  patient: z.object({
    name: ZName,
    nhs_number: ZNHSNumberValidator,
  }),
});
