import { AppBar, Box } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'App/routes';
import { Avo } from 'infrastructure/analytics';
import LastSyncedBanner from 'pages/PlannerListPage/components/LastSyncedBanner';

import HeaderContent from './components/HeaderContent';
import HelpModal from './components/HelpModal';

export default function Header() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const isPlannerRoute = location.pathname.startsWith(ROUTES.planner);

  return (
    <>
      {isPlannerRoute && <LastSyncedBanner />}

      <Box
        component="header"
        sx={{
          backgroundColor: 'white',
          borderBottom: (theme) => `1px solid ${theme.palette.colors.grey}`,
        }}
      >
        <AppBar
          component="nav"
          position="static"
          sx={{
            paddingX: '16px',
            boxShadow: 'none',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'white',
            justifyContent: 'space-between',
            minHeight: '48px',
          }}
        >
          <HeaderContent handleOpenHelpModal={() => setIsOpen(true)} />
        </AppBar>
      </Box>

      <HelpModal
        open={isOpen}
        handleClose={() => {
          Avo.helpModalClosed();
          setIsOpen(false);
        }}
      />
    </>
  );
}
