import { ExpandMore } from '@mui/icons-material';
import {
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ROUTES } from 'App/routes';
import { Avo } from 'infrastructure/analytics';
import { TabValueType } from 'infrastructure/analytics/Avo';
import { usePlannerParams } from 'pages/PlannerListPage/hooks/usePlannerParams';

const headerTabStyles = {
  fontWeight: 400,
  color: 'text.primary',
  '&.Mui-selected': {
    color: 'text.primary',
    fontWeight: 600,
  },
  paddingY: '8px',
  paddingX: '0px',
  marginX: '16px',
};

const useTabValue = () => {
  const location = useLocation();

  if (location.pathname.includes(ROUTES.your_patients))
    return ROUTES.your_patients;
  if (location.pathname.includes(ROUTES.suvera_patients))
    return ROUTES.suvera_patients;
  return false;
};

const eventTabNameFromRoute = (value: string): TabValueType | null => {
  if (value === ROUTES.your_patients) return 'your_patients';
  if (value === ROUTES.suvera_patients) return 'suvera_patients';
  if (value === ROUTES.create_referral) return 'create_referral';
  if (value === ROUTES.past_referrals) return 'past_referrals';
  if (value === 'referrals-menu') return 'referrals';
  return null;
};

export default function NavigationTabs() {
  const navigate = useNavigate();
  const tabValue = useTabValue();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [searchParams] = useSearchParams();
  const plannerParams = usePlannerParams();

  const menuIsOpen = Boolean(anchor);
  const handleMenuClose = () => {
    setAnchor(null);
  };

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.delete('subCohort');
    updatedSearchParams.delete('targetAreaState');
    updatedSearchParams.set('page', '0');
    updatedSearchParams.set('targetArea', plannerParams.targetArea);
    updatedSearchParams.set(
      'cohort',
      newValue === ROUTES.your_patients ? 'all-practice' : 'all-suvera',
    );

    const tab = eventTabNameFromRoute(newValue);
    if (tab) {
      Avo.navigationButtonClicked({ tab });
    }

    switch (newValue) {
      case 'referrals-menu': {
        setAnchor(event.currentTarget as HTMLElement);

        break;
      }
      case ROUTES.your_patients:
      case ROUTES.suvera_patients: {
        navigate(`${newValue}?${updatedSearchParams.toString()}`);

        break;
      }
      default: {
        navigate(newValue);
      }
    }
  };

  const handleMenuClick = (route: string) => () => {
    const tab = eventTabNameFromRoute(route);
    if (tab) {
      Avo.navigationButtonClicked({ tab });
    }
    navigate(route);
    handleMenuClose();
  };

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        sx={{ flex: 1 }}
        TabIndicatorProps={{ style: { height: '4px' } }}
      >
        <Tab
          value={ROUTES.your_patients}
          sx={headerTabStyles}
          label="Your patients"
        />
        <Tab
          value={ROUTES.suvera_patients}
          sx={headerTabStyles}
          label="Suvera patients"
        />
        <Tab
          value="referrals-menu"
          sx={headerTabStyles}
          label={
            <Stack direction="row" alignItems="center" spacing="8px">
              <Typography>Referrals</Typography>
              <ExpandMore
                sx={{
                  fontSize: '16px',
                  transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease-in-out',
                }}
              />
            </Stack>
          }
        />
      </Tabs>

      <Menu
        anchorEl={anchor}
        keepMounted
        open={menuIsOpen}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            sx: {
              marginTop: '-4px',
            },
          },
        }}
      >
        <MenuItem onClick={handleMenuClick(ROUTES.create_referral)}>
          <ListItemText primary="Create a referral" />
        </MenuItem>
        <MenuItem onClick={handleMenuClick(ROUTES.past_referrals)}>
          <ListItemText primary="Past referrals" />
        </MenuItem>
      </Menu>
    </>
  );
}
