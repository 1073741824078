import { CheckCircle, Error, SimCardDownload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import FileSaver from 'file-saver';

import { Avo } from 'infrastructure/analytics';
import { useState } from 'react';
import { usePlannerParams } from '../hooks/usePlannerParams';
import { useExportPatientList } from '../requests/useExportPatientList';

export default function ExportListButton({ disabled }: { disabled: boolean }) {
  const [errorSnackbarIsShown, setErrorSnackbarIsShown] = useState(false);
  const [successSnackbarIsShown, setSuccessSnackbarIsShown] = useState(false);

  const { page, ...params } = usePlannerParams();
  const exportPatientList = useExportPatientList(params);

  const handleExportClick = async () => {
    Avo.exportListButtonClicked();
    const { data, isError, isSuccess } = await exportPatientList.refetch();

    if (isError) {
      setErrorSnackbarIsShown(true);
      Avo.exportListErrorMessageViewed();
    }

    if (isSuccess && data) {
      setSuccessSnackbarIsShown(true);
      FileSaver.saveAs(data.blob, data.fileName);
      Avo.exportListSuccessMessageViewed();
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={errorSnackbarIsShown}
        onClose={() => setErrorSnackbarIsShown(false)}
        autoHideDuration={4000}
      >
        <Alert
          severity="error"
          variant="standard"
          onClose={() => setErrorSnackbarIsShown(false)}
          icon={<Error fontSize="inherit" />}
          sx={{
            width: '420px',
            borderWidth: '0 0 0 6px',
            borderColor: 'colors.red',
            borderStyle: 'solid',
            color: 'colors.black',
          }}
        >
          <AlertTitle sx={{ fontWeight: 'bold' }}>Error</AlertTitle>
          Error exporting file, please try again
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={successSnackbarIsShown}
        onClose={() => setSuccessSnackbarIsShown(false)}
        autoHideDuration={4000}
      >
        <Alert
          severity="success"
          variant="standard"
          onClose={() => setSuccessSnackbarIsShown(false)}
          icon={<CheckCircle fontSize="inherit" />}
          sx={{
            width: '420px',
            borderWidth: '0 0 0 6px',
            borderColor: 'colors.green',
            borderStyle: 'solid',
            color: 'colors.black',
          }}
        >
          <AlertTitle sx={{ fontWeight: 'bold' }}>Export successful</AlertTitle>
        </Alert>
      </Snackbar>

      <LoadingButton
        startIcon={<SimCardDownload />}
        onClick={handleExportClick}
        loading={exportPatientList.isLoading}
        disabled={disabled}
        variant="contained"
        size="small"
        sx={{
          padding: '4px 8px',
        }}
      >
        Export list
      </LoadingButton>
    </>
  );
}
