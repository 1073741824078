import axios, { AxiosError } from 'axios';
import { useAuthenticatedAxios } from 'infrastructure/suveraApi';
import { useQuery } from 'react-query';
import { IAppointment, ZAppointment } from 'schemas/appointments';
import { z } from 'zod';

const getUpcomingPatientAppointments = z.object({
  result: z.array(ZAppointment),
});

type GetUpcomingPatientAppointmentsResponse = z.infer<
  typeof getUpcomingPatientAppointments
>;

export const useGetUpcomingPatientAppointments = (patientID: string) => {
  const suveraAxiosInstance = useAuthenticatedAxios();
  return useQuery({
    queryKey: ['upcoming-appointments', patientID],
    queryFn: async (): Promise<IAppointment[] | undefined> => {
      return suveraAxiosInstance
        .get<GetUpcomingPatientAppointmentsResponse>(
          `/patients/${patientID}/upcoming-appointments`,
        )
        .then(
          (response) =>
            getUpcomingPatientAppointments.parse(response.data).result,
        );
    },
    retry: false,
    onError: (error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        return;
      }
      throw error;
    },
  });
};
