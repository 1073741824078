import { z } from 'zod';
import { ZDateString, ZObjectId, ZPartner } from '@suvera/core-types';
import { ZPatientRedacted } from './patient.redacted';

const ZReferralStatusType = z.enum([
  'received',
  'acknowledged',
  'accepted',
  'rejected',
]);

const ZReferralStatus = z.object({
  type: ZReferralStatusType,
  datetime: ZDateString,
  note: z.string(),
});

export const ZReferral = z.object({
  _id: ZObjectId,
  patient: ZPatientRedacted,
  note: z.string(),
  partner: ZPartner.omit({ practices: true }),
  created_at: ZDateString,
  status: ZReferralStatus,
});

export type IReferral = z.infer<typeof ZReferral>;
export type IReferralStatus = IReferral['status'];
export type EReferralStatusType = IReferralStatus['type'];
