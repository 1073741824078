import { Suspense, lazy, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetAuthenticatedPartner } from 'App/PartnerAuth/requests';
import LoadingState from 'components/LoadingState';
import Page from 'components/Page';

import NoOnboardingConfigPage from 'pages/NoOnboardingConfigPage/NoOnboardingConfigPage';
import { Box } from '@mui/material';
import { usePlannerParams } from './hooks/usePlannerParams';
import { useGetPatientList } from './requests/useGetPatientList';

const PlannerListPageLazy = lazy(
  () => import(/* webpackChunkName: "PlannerListPage" */ './PlannerListPage'),
);

const PlannerListPage = () => {
  const partner = useGetAuthenticatedPartner().data;

  const [searchParams, setSearchParams] = useSearchParams();
  const plannerParams = usePlannerParams();
  const { isLoading, isError, data } = useGetPatientList(plannerParams);

  const hasNoSearchParams = searchParams.size === 0;

  useEffect(() => {
    if (hasNoSearchParams) {
      setSearchParams({
        cohort: plannerParams.cohort,
        targetArea: plannerParams.targetArea,
        page: plannerParams.page.toString(),
      });
    }
  });

  if (
    partner?.practice.contractedTargetAreas.length === 0 ||
    !partner?.practice.onboardingRulesDescription
  ) {
    return <NoOnboardingConfigPage />;
  }

  return (
    <Suspense fallback={<LoadingState />}>
      <Page>
        <Box data-testid="planner-V3-page">
          <PlannerListPageLazy
            isLoading={isLoading || hasNoSearchParams}
            isError={isError}
            data={data}
          />
        </Box>
      </Page>
    </Suspense>
  );
};

export default PlannerListPage;
