import camelcaseKeys from 'camelcase-keys';
import { parseISO } from 'date-fns/fp';
import { z } from 'zod';

export const ZAppointment = z
  .object({
    _id: z.string(),
    datetime: z.string().transform((dateString) => parseISO(dateString)),
    suverian: z.object({
      name: z.string(),
    }),
  })
  .transform((patient) => camelcaseKeys(patient));

export type IAppointment = z.infer<typeof ZAppointment>;
