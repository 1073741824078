import { isValidMod11Number } from '@suvera/core-types/types/nhsNumbers';
import { z } from 'zod';

export const sanitiseNhsNumber = (value: string) =>
  value.replaceAll(/[\s-]/g, '');

export const nhsNumberSchema = z
  .string({ required_error: 'required' })
  .refine((sanitisedNumber) => /^[\d\s-]+$/.test(sanitisedNumber), {
    message: 'invalid_characters',
  })
  .transform(sanitiseNhsNumber)
  .refine((sanitisedNumber) => sanitisedNumber.length <= 10, {
    message: 'too_long',
  })
  .refine((sanitisedNumber) => sanitisedNumber.length >= 10, {
    message: 'too_short',
  })
  .refine(
    (sanitisedNumber) =>
      sanitisedNumber.length === 10 && isValidMod11Number(sanitisedNumber),
    {
      message: 'invalid_number',
    },
  );
