import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

import { createSuveraPalette } from './palette';
import { typography } from './typography';
import { muiDataGridOverrides } from './overrides/muiDataGrid';

const palette = createSuveraPalette({});

export const theme = createTheme({
  typography,
  palette,
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: '8px 12px',
          borderRadius: '4px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: palette.colors.greyLight,
        },
        standardInfo: {
          color: palette.colors.black,
          backgroundColor: palette.colors.blueLight,
          '.MuiAlert-icon': { color: palette.colors.blue },
        },
        standardWarning: {
          color: palette.text.primary,
          backgroundColor: palette.warning.light,
          '.MuiAlert-icon': { color: palette.warning.main },
        },
        standardError: {
          color: palette.text.primary,
          backgroundColor: palette.error.light,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          padding: '12px 16px',
          fontSize: '16px',
          '&.MuiButton-outlined': {
            padding: '10px 14px',
          },
        },
        sizeMedium: {
          padding: '8px 8px',
          fontSize: '16px',
          '&.MuiButton-outlined': {
            padding: '6px 6px',
          },
        },
        sizeSmall: {
          padding: '6px 8px',
          fontSize: '14px !important',
          height: '32px',
          '&.MuiButton-outlined': {
            padding: '4px 6px',
          },
        },
        outlined: {
          backgroundColor: palette.colors.white,
          borderColor: palette.colors.blue,
          borderWidth: '2px',
          '&:hover': {
            color: palette.colors.blueDark,
            borderColor: palette.colors.blueDark,
            borderWidth: '2px',
          },
        },
        text: {
          '&:hover': {
            color: palette.colors.blueDark,
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '14px',
        },
        sizeSmall: {
          padding: '2px 0',
          height: 'auto',
        },
        outlined: {
          borderRadius: '4px',
          backgroundColor: palette.colors.greyLightest,
          borderColor: palette.colors.grey,
        },
        outlinedPrimary: {
          borderColor: palette.colors.blue,
          backgroundColor: palette.colors.blueLight,
          color: palette.colors.black,
        },
      },
    },
    MuiDataGrid: muiDataGridOverrides,
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.colors.grey,
          borderBottomWidth: '0.5px',
        },
        vertical: {
          borderRightWidth: '0.5px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...typography.body2,
          color: palette.colors.greyDark,
          fontSize: '14px',
          marginBottom: '8px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: palette.error.main,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textUnderlineOffset: '2px',
          color: palette.colors.blue,
          textDecorationColor: palette.colors.blue,
          ':hover': {
            color: palette.colors.blueDark,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginTop: '4px',
          borderRadius: '4px',
          border: 'solid 0.5px',
          borderColor: palette.colors.grey,
          boxShadow: '0 4px 4px 0 rgba(15, 27, 56, 0.08)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '4px 12px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& input': {
            padding: '8px 12px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: `0.5px solid ${palette.colors.black}`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `0.5px solid ${palette.colors.blue}`,
          },
        },
        notchedOutline: {
          border: `0.5px solid ${palette.colors.grey}`,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '8px 32px 8px 12px',
        },
      },
    },
  },
});
