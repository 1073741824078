import { z } from 'zod';

const FormQuestionnaireIds = ['lifestyle', 'asthma'] as const;
const ZFormQuestionnaireIDs = z.enum(FormQuestionnaireIds);
export type FormQuestionnaireID = z.infer<typeof ZFormQuestionnaireIDs>;

export const AllQuestionnaireIds = [
  ...FormQuestionnaireIds,
  'blood_pressure',
] as const;
const ZAllQuestionnaireIDs = z.enum(AllQuestionnaireIds);
export type QuestionnaireID = z.infer<typeof ZAllQuestionnaireIDs>;

export const AllClinicianCompletableQuestionnaireFormTypes = [
  'diabetes_info',
  ...AllQuestionnaireIds,
] as const;
export const ZClinicianCompletableQuestionnaireFormType = z.enum(
  AllClinicianCompletableQuestionnaireFormTypes,
);
export type ClinicianCompletableQuestionnaireFormType = z.infer<
  typeof ZClinicianCompletableQuestionnaireFormType
>;
