import { ThemeProvider } from '@mui/material';
import { PropsWithChildren } from 'react';

import { theme } from './theme';

export const SuveraThemeProvider = ({
  children,
}: PropsWithChildren<object>) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
