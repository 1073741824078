import { Colors, colors, BrandColors, brandColors } from './colors';

type RagColor = { default: string; light?: string };
type SuveraPalette = {
  primary: {
    main: string;
    dark: string;
  };
  text: {
    primary: string;
    secondary: string;
  };
  error: { main: string; light: string };
  warning: { main: string; light: string };
  success: {
    main: string;
    light: string;
  };
  RAG: {
    controlled: RagColor;
    low: RagColor;
    medium: RagColor;
    high: RagColor;
    urgent: RagColor;
  };
  borders: { light: string; main: string };
  colors: Colors;
  brandColors: BrandColors;
};

declare module '@mui/material/styles/createPalette' {
  export interface Palette extends SuveraPalette {}

  export interface PaletteOptions extends SuveraPalette {}
}

export const createSuveraPalette = (
  overrides: Partial<SuveraPalette>,
): SuveraPalette => ({
  primary: {
    main: colors.blue,
    dark: colors.blueDark,
  },
  text: {
    primary: colors.black,
    secondary: colors.greyDark,
  },
  error: { main: colors.red, light: colors.redLight },
  warning: { main: colors.amber, light: colors.amberLight },
  success: {
    main: colors.green,
    light: colors.greenLight,
  },
  RAG: {
    controlled: {
      default: colors.blue,
    },
    low: {
      default: colors.green,
    },
    medium: {
      default: colors.yellow20,
    },
    high: {
      default: colors.amber20,
    },
    urgent: {
      default: colors.red,
      light: colors.redLight,
    },
  },
  borders: { light: colors.grey40, main: colors.grey60 },
  colors,
  brandColors,
  ...overrides,
});
