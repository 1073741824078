import z from 'zod';
import { ZPartner, ZReferralTask } from '@suvera/core-types';
import { ZPatientRedacted } from './patient.redacted';

export const ZReferralTaskPopulated = ZReferralTask.omit({
  patient: true,
  partner: true,
  task_type: true,
}).extend({
  patient: ZPatientRedacted,
  partner: ZPartner,
  task_type: z.literal('gp_referral'),
});

export type IReferralTaskPopulated = z.infer<typeof ZReferralTaskPopulated>;
