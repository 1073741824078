import z from 'zod';

export const ZAddress = z.object({
  first_line: z.string().optional(),
  second_line: z.string().optional(),
  third_line: z.string().optional(),
  locality: z.string().optional(),
  region: z.string().optional(),
  postal_code: z.string().optional(),
  country: z.string().optional(),
});
export type IAddress = z.infer<typeof ZAddress>;
