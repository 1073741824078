import type { z } from 'zod';

import { ZObjectId, ZPartner } from '@suvera/core-types';

export const ZCreatePartner = ZPartner.omit({
  _id: true,
  practices: true,
}).extend({
  practice: ZObjectId,
});

export type ICreatePartner = z.infer<typeof ZCreatePartner>;
