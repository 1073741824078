import { ZPatient, targetAreaKeys } from '@suvera/core-types';
import { z } from 'zod';

/* API responses */

const ZPlannerPatientPickedFields = ZPatient.pick({
  _id: true,
  nhs_number: true,
  name: true,
  date_of_birth: true,
});
export const ZPlannerPatient = ZPlannerPatientPickedFields.extend({
  practiceLedTargetAreas: z.string().array(),
  suveraLedTargetAreas: z.string().array(),
  uclpRisk: z.string().nullable(),
});

export type IPlannerPatient = z.infer<typeof ZPlannerPatient>;

/* User filters */

export const ZMonthOfBirthFilter = z.array(z.number().min(1).max(12));
export type BirthMonthFilter = z.infer<typeof ZMonthOfBirthFilter>;

export const ZTargetAreaStateFilter = z.array(
  z.enum(['complete', 'incomplete']),
);
export type TargetAreaStateFilter = z.infer<typeof ZTargetAreaStateFilter>;

export const ZPageFilter = z.number().min(0).default(0);
export type PageFilter = z.infer<typeof ZPageFilter>;

export const ZTargetAreaFilter = z.enum(targetAreaKeys);
export type ETargetAreaFilter = z.infer<typeof ZTargetAreaFilter>;

/* Cohort / Segment filters */

const cohortFilterOptions = [
  'all-practice',
  'discharged',
  'excluded',
  'all-suvera',
  'active',
  'onboarding',
  'waiting-to-onboard',
] as const;
export const ZCohort = z.enum(cohortFilterOptions);
export type ECohort = z.infer<typeof ZCohort>;
export const ZCohortFilter = ZCohort;

const subCohortsBackendOnlyOptions = [
  'requesting-data',
  'review-scheduled',
  'review-complete',
  'target-complete',
] as const;
const subCohortsFilterOptions = [
  'all-active',
  ...subCohortsBackendOnlyOptions,
] as const;
export const ZSubCohort = z.enum(subCohortsFilterOptions);
export type ESubCohort = z.infer<typeof ZSubCohort>;
export const ZSubCohortFilter = ZSubCohort.nullable();

export const segmentFilterOptions = [
  'active',
  'discharged',
  'not-invited-practice-rules',
  'not-invited-suvera-rules',
  'tangential',
  'next-to-onboard',
  'onboarding-later',
  ...subCohortsBackendOnlyOptions,
] as const;
export const ZSegment = z.enum(segmentFilterOptions);
export type ESegment = z.infer<typeof ZSegment>;

/* Tab to segment mappings */

type CohortTemplate = `${string}<Patients>${string}`;

export type ICohortDetails = {
  label: string;
  value: ECohort;
  segments: ESegment[];
  template: CohortTemplate;
  description: string;
};

export const practiceCohorts: Partial<Record<ECohort, ICohortDetails>> = {
  'all-practice': {
    label: 'All',
    value: 'all-practice',
    segments: [
      'not-invited-practice-rules',
      'not-invited-suvera-rules',
      'tangential',
      'discharged',
    ],
    template: 'Your <Patients>',
    description: "Patients who are currently under your practice's care.",
  },
  discharged: {
    label: 'Discharged from Suvera',
    value: 'discharged',
    segments: ['discharged'],
    template: 'Discharged <Patients>',
    description: 'Patients who have been discharged from Suvera.',
  },
  excluded: {
    label: 'Excluded from Suvera',
    value: 'excluded',
    segments: [
      'not-invited-practice-rules',
      'not-invited-suvera-rules',
      'tangential',
    ],
    template: 'Excluded <Patients>',
    description:
      "Patients who have been excluded from Suvera based on practice or Suvera's exclusion rules.",
  },
};

export const suveraCohorts: Partial<Record<ECohort, ICohortDetails>> = {
  'all-suvera': {
    label: 'All',
    value: 'all-suvera',
    segments: ['active', 'next-to-onboard', 'onboarding-later'],
    template: "Suvera's <Patients>",
    description:
      'Patients who are currently, or are about to be, under the care of Suvera.',
  },
  active: {
    label: 'Active',
    value: 'active',
    segments: ['active'],
    template: 'Active <Patients>',
    description:
      'Patients who are currently under the care of Suvera and using the service.',
  },
  onboarding: {
    label: 'Onboarding',
    value: 'onboarding',
    segments: ['next-to-onboard'],
    template: 'Onboarding <Patients>',
    description:
      'Patients who are currently being onboarded and will be invited to Suvera soon.',
  },
  'waiting-to-onboard': {
    label: 'Waiting to onboard',
    value: 'waiting-to-onboard',
    segments: ['onboarding-later'],
    template: '<Patients> waiting to onboard',
    description:
      'Patients who are waiting to be onboarded and invited to use Suvera.',
  },
};

export type ISubCohortDetails = {
  label: string;
  value: ESubCohort;
  segments: ESegment[];
};

export const activeSubCohorts: Record<ESubCohort, ISubCohortDetails> = {
  'all-active': {
    label: 'All active',
    value: 'all-active',
    segments: ['active'],
  },
  'requesting-data': {
    label: 'Requesting data',
    value: 'requesting-data',
    segments: ['requesting-data'],
  },
  'review-scheduled': {
    label: 'Review scheduled',
    value: 'review-scheduled',
    segments: ['review-scheduled'],
  },
  'review-complete': {
    label: 'Review complete',
    value: 'review-complete',
    segments: ['review-complete'],
  },
  'target-complete': {
    label: 'Target area complete',
    value: 'target-complete',
    segments: ['target-complete'],
  },
};

export const cohorts = {
  ...practiceCohorts,
  ...suveraCohorts,
} as Record<ECohort, ICohortDetails>;
