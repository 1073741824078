import { Logout, OpenInNew } from '@mui/icons-material';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ROUTES } from 'App/routes';
import UserBadge from 'components/UserBadge';
import { PRIVACY_NOTICE_URL, USER_GUIDE_URL } from 'consts';
import { Avo } from 'infrastructure/analytics';

import { usePartnerName } from '../hooks/usePartnerName';

type Props = {
  handleOpenHelpModal: () => void;
};

export default function NavigationMenu({ handleOpenHelpModal }: Props) {
  const navigate = useNavigate();
  const { avatarText, partnerName } = usePartnerName();

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const menuIsOpen = Boolean(anchor);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    Avo.headerNavigationMenuOpened();
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchor(null);
  };

  return (
    <>
      <UserBadge
        isOpen={menuIsOpen}
        onClick={handleMenuOpen}
        avatarText={avatarText}
        ariaLabel="Navigation menu"
        id="navigation-menu-button"
      />

      <Menu
        id="user-menu"
        aria-labelledby="user-menu-button"
        anchorEl={anchor}
        open={menuIsOpen}
        onClose={() => {
          Avo.headerNavigationMenuClosed();
          handleMenuClose();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '4px',
              width: '250px',
            },
          },
        }}
      >
        <Stack spacing="8px">
          <Stack>
            <Typography paddingX="12px" paddingY="2px" color="colors.grey">
              Signed in as
            </Typography>

            <Typography paddingX="12px" paddingY="2px">
              {partnerName}
            </Typography>
          </Stack>

          <Divider />

          <Stack>
            <MenuItem
              onClick={() => {
                Avo.helpModalOpened();
                handleOpenHelpModal();
                handleMenuClose();
              }}
            >
              <ListItemText>Help & contact</ListItemText>
            </MenuItem>

            <Link
              to={USER_GUIDE_URL}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
              onClick={() => {
                Avo.userGuideButtonClicked();
                handleMenuClose();
              }}
            >
              <MenuItem>
                <ListItemText sx={{ flex: 'unset', marginRight: '4px' }}>
                  User guide
                </ListItemText>

                <ListItemIcon
                  sx={{ color: 'colors.black', marginRight: '-12px' }}
                >
                  <OpenInNew fontSize="small" />
                </ListItemIcon>
              </MenuItem>
            </Link>

            <Link
              to={PRIVACY_NOTICE_URL}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
              onClick={() => {
                Avo.privacyNoticeButtonClicked();
                handleMenuClose();
              }}
            >
              <MenuItem>
                <ListItemText sx={{ flex: 'unset', marginRight: '4px' }}>
                  Privacy notice
                </ListItemText>

                <ListItemIcon
                  sx={{ color: 'colors.black', marginRight: '-12px' }}
                >
                  <OpenInNew fontSize="small" />
                </ListItemIcon>
              </MenuItem>
            </Link>
          </Stack>

          <Divider />

          <MenuItem
            onClick={() => {
              Avo.signOutButtonClicked();
              navigate(ROUTES.logout);
            }}
          >
            <ListItemIcon sx={{ color: 'colors.black', marginRight: '-12px' }}>
              <Logout fontSize="small" />
            </ListItemIcon>

            <ListItemText>Sign out</ListItemText>
          </MenuItem>
        </Stack>
      </Menu>
    </>
  );
}
