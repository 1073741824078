import { z, ZodFirstPartyTypeKind } from 'zod';

export const ZDateString = z.preprocess(
  (arg) => {
    return typeof arg === 'string' || arg instanceof Date
      ? new Date(arg)
      : undefined;
  },
  z.date(),
  {
    description: ZodFirstPartyTypeKind.ZodDate,
  },
);
