import { FC, ReactNode } from 'react';

import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { SuveraThemeProvider } from '@suvera/theme';

import { ErrorBoundaryFallbackPage } from 'components/Errors';
import { SuveraApiQueryProvider } from 'infrastructure/ReactQuery';
import PageTracker from 'infrastructure/analytics/PageTracker';

import { UserListener } from './PartnerAuth';
import PartnerDashboardRoutes from './PartnerDashboardRoutes';

interface AppProps {
  children?: ReactNode;
}

const App: FC<AppProps> = ({ children }) => {
  return (
    <SuveraThemeProvider>
      <ErrorBoundary fallback={<ErrorBoundaryFallbackPage />}>
        <SuveraApiQueryProvider>
          <>
            <UserListener />
            <PageTracker />
            {children || <PartnerDashboardRoutes />}{' '}
          </>
        </SuveraApiQueryProvider>
      </ErrorBoundary>
    </SuveraThemeProvider>
  );
};

export default Sentry.withProfiler(App);
