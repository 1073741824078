import { lazy, Suspense } from 'react';
import LoadingState from 'components/LoadingState';

const CreateReferralPageLazy = lazy(
  () =>
    import(/* webpackChunkName: "CreateReferralPage" */ './CreateReferralPage'),
);

const CreateReferralPage = () => {
  return (
    <Suspense fallback={<LoadingState />}>
      <CreateReferralPageLazy />
    </Suspense>
  );
};

export default CreateReferralPage;
