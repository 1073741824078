import { z } from 'zod';

import { ZEntryObject, ZPatient } from './patient';
import { ZTargetsEHRPatient } from './targets';

export const ZEHRPatient = ZPatient.omit({
  _id: true,
  ehrExtractedCodedEntries: true,
}).extend({
  id: z.string(),
  action: z.enum([
    'create',
    'update',
    'error - no practice with correct odsCode',
  ]),
  phone_numbers: z.array(z.string()),
  targetAreas: ZTargetsEHRPatient.optional(),
  unionedCodeReports: z.array(ZEntryObject),
});

export type IEHRPatient = z.infer<typeof ZEHRPatient>;
