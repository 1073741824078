import {
  Box,
  Chip,
  ChipProps,
  CircularProgress,
  Typography,
} from '@mui/material';
import { format } from 'date-fns/fp';
import { FC, ReactNode, useState } from 'react';

import ErrorSnackbar from 'components/ErrorSnackbar';

import { useGetUpcomingPatientAppointments } from '../hooks/useGetUpcomingPatientAppointments';

const formatDate = format('d MMMM yyyy');
const formatTime = format('H:mm');

const AppointmentsChip: FC<
  Omit<ChipProps, 'children'> & { children: ReactNode }
> = ({ children, ...props }) => {
  return (
    <Chip
      sx={{
        width: '100%',
        height: 'unset',
        paddingY: '16px',
        '& .MuiChip-label': {
          whiteSpace: 'break-spaces',
        },
      }}
      variant="outlined"
      label={<Typography variant="body2">{children}</Typography>}
      {...props}
    />
  );
};

const UpcomingAppointmentsErrorState = () => {
  const [alertIsShown, setAlertIsShown] = useState<boolean>(true);

  return (
    <>
      <ErrorSnackbar
        isOpen={alertIsShown}
        onClose={() => setAlertIsShown(false)}
        message="Error loading upcoming appointments. Try reloading the page."
      ></ErrorSnackbar>
      <AppointmentsChip style={{ height: '36px' }}>
        Error loading upcoming appointments
      </AppointmentsChip>
    </>
  );
};

export default function UpcomingAppointments({
  patientId,
}: {
  patientId: string;
}) {
  const getUpcomingPatientAppointments =
    useGetUpcomingPatientAppointments(patientId);

  if (getUpcomingPatientAppointments.isLoading)
    return (
      <Box
        sx={{
          width: '100%',
          height: '58px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (getUpcomingPatientAppointments.error) {
    return <UpcomingAppointmentsErrorState />;
  }

  if (
    getUpcomingPatientAppointments.data &&
    getUpcomingPatientAppointments.data?.length > 0
  )
    return (
      <>
        {getUpcomingPatientAppointments.data.map((appointment) => (
          <AppointmentsChip
            data-testid={`appointment_${appointment.id}`}
            key={appointment.id}
            color="primary"
          >
            <strong>Telephone appointment</strong> on{' '}
            <strong>{formatDate(appointment.datetime)}</strong> at{' '}
            <strong>{formatTime(appointment.datetime)}</strong> with{' '}
            <strong>{appointment.suverian.name}</strong>
          </AppointmentsChip>
        ))}
      </>
    );

  return (
    <AppointmentsChip style={{ height: '36px' }}>
      No upcoming appointments
    </AppointmentsChip>
  );
}
