import { z } from 'zod';

export const ZChanges = z.object({
  key: z.string(),
  oldValue: z.any(),
  newValue: z.any(),
});
export type IChanges = z.infer<typeof ZChanges>;

// TODO ideally something tighter here - originally had this
// export interface IChanges<T, K extends keyof T> {
//   key: K;
//   oldValue: T[K];
//   newValue: T[K];
// }
