import { z } from 'zod';
import ZodThirdPartyTypeKind from './ZodThirdPartyTypeKind';

export const ZObjectId: z.ZodEffects<z.ZodType<string, z.ZodTypeDef, unknown>> =
  z.preprocess(
    (id): string | undefined => {
      if (typeof id === 'string') return id;
      if (typeof id === 'object' && id && 'toString' in id)
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        return id.toString();
      return undefined;
    },
    z.custom<string>((id) => {
      const castID = id as string;
      // must be 24 hexadecimal (0-9, a-f) characters
      const valid = /^[\da-f]{24}$/.test(castID);
      return valid;
    }),
    {
      description: ZodThirdPartyTypeKind.SuveraMongoObjectId,
    },
  );
