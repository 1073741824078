import {
  BirthMonthFilter,
  ETargetAreaFilter,
  IGetPatientListRequest,
  TargetAreaStateFilter,
  ZCohortFilter,
  ZMonthOfBirthFilter,
  ZPageFilter,
  ZSubCohortFilter,
  ZTargetAreaFilter,
  ZTargetAreaStateFilter,
} from '@suvera/api-types';
import { useSearchParams } from 'react-router-dom';

import { useLocationIncludesYourPatients } from './useLocationIncludesYourPatients';
import { usePracticeTargetAreas } from './usePracticeTargetAreas';

export const usePlannerParams = (): IGetPatientListRequest => {
  const [searchParams] = useSearchParams();
  const { contractedTargetAreas } = usePracticeTargetAreas();
  const locationIncludesYourPatients = useLocationIncludesYourPatients();

  const searchParamsAreEmpty = [...searchParams.entries()].length === 0;

  const defaultValues: IGetPatientListRequest = {
    cohort: locationIncludesYourPatients ? 'all-practice' : 'all-suvera',
    subCohort: null,
    monthsOfBirth: [] as BirthMonthFilter,
    page: 0,
    targetArea: 'nonDiabeticHyperglycaemia' as ETargetAreaFilter,
    targetAreaStates: [] as TargetAreaStateFilter,
  };

  if (searchParamsAreEmpty) {
    return {
      ...defaultValues,
      targetArea: contractedTargetAreas[0],
    } as IGetPatientListRequest;
  }

  const cohortParsed = ZCohortFilter.safeParse(searchParams.get('cohort'));
  const subCohortParsed = ZSubCohortFilter.safeParse(
    searchParams.get('subCohort'),
  );
  const monthsOfBirthParsed = ZMonthOfBirthFilter.safeParse(
    searchParams
      .getAll('monthOfBirth')
      .map((month) => Number.parseInt(month, 10)),
  );
  const targetAreaStateParsed = ZTargetAreaStateFilter.safeParse(
    searchParams.getAll('targetAreaState'),
  );
  const targetAreaParsed = ZTargetAreaFilter.safeParse(
    searchParams.get('targetArea'),
  );
  const pageParsed = ZPageFilter.safeParse(
    Number.parseInt(searchParams.get('page') ?? '', 10),
  );

  const cohort = cohortParsed.success
    ? cohortParsed.data
    : defaultValues.cohort;
  const subCohort = subCohortParsed.success
    ? subCohortParsed.data
    : defaultValues.subCohort;
  const monthsOfBirth = monthsOfBirthParsed.success
    ? monthsOfBirthParsed.data
    : defaultValues.monthsOfBirth;
  const targetArea = targetAreaParsed.success
    ? targetAreaParsed.data
    : defaultValues.targetArea;
  const targetAreaStates = targetAreaStateParsed.success
    ? targetAreaStateParsed.data
    : defaultValues.targetAreaStates;
  const page = pageParsed.success ? pageParsed.data : defaultValues.page;

  return {
    cohort,
    subCohort,
    monthsOfBirth,
    page,
    targetArea,
    targetAreaStates,
  } as IGetPatientListRequest;
};
