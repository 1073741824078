import { lazy, Suspense } from 'react';
import LoadingState from 'components/LoadingState';

const PastReferralsPageLazy = lazy(
  () =>
    import(/* webpackChunkName: "PastReferralsPage" */ './PastReferralsPage'),
);

const PastReferralsPage = () => {
  return (
    <Suspense fallback={<LoadingState />}>
      <PastReferralsPageLazy />
    </Suspense>
  );
};

export default PastReferralsPage;
