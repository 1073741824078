import { ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

type Props = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isOpen: boolean;
  avatarText: string;
  ariaLabel: string;
  id: string;
};

export default function UserBadge({
  onClick,
  isOpen,
  avatarText,
  ariaLabel,
  id,
}: Props) {
  return (
    <IconButton
      onClick={onClick}
      aria-label={ariaLabel}
      id={id}
      sx={{
        backgroundColor: 'colors.greyLight',
        borderRadius: '32px',
        padding: '2px',
        color: 'colors.black',
        '&:hover': {
          backgroundColor: 'colors.grey',
        },
      }}
    >
      <Box
        sx={{
          height: '28px',
          width: '28px',
          backgroundColor: 'colors.white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
        }}
      >
        <Typography
          variant="body2"
          fontWeight={600}
          style={{
            lineHeight: 1.25,
            fontSize: '12px',
          }}
        >
          {avatarText}
        </Typography>
      </Box>

      <ExpandMore
        sx={{
          fontSize: '20px',
          marginRight: '2px',
          transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s ease-in-out',
        }}
      />
    </IconButton>
  );
}
