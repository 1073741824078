import { z } from 'zod';
import { EClinicalReadingTypeList } from './dictionary/clinicalReadingTypeDictionary';
import { ZObjectId } from './objectid';
import { AllTargetNames, TargetNames, ZTargetAreaKeys } from './targets';
import { ZDateString } from './date';

const ZCodeFilter = z.object({
  codes: z.array(z.enum(EClinicalReadingTypeList)),
  dateRange: z.enum(['trailing_12_months', 'this_financial_year']).optional(),
  valueRange: z.object({ min: z.number(), max: z.number() }).optional(),
});

const ZTargetAreaFilter = z.object({
  targetArea: ZTargetAreaKeys,
});

const ZIncompleteTargetFilter = z.object({
  incompleteTargets: z.array(
    z.enum(AllTargetNames as [TargetNames, ...TargetNames[]]),
  ),
});

const ZCompleteTargetFilter = z.object({
  completeTargets: z.array(
    z.enum(AllTargetNames as [TargetNames, ...TargetNames[]]),
  ),
});

const ZNotIncompleteTargetFilter = z.object({
  notIncompleteTargets: z.array(
    z.enum(AllTargetNames as [TargetNames, ...TargetNames[]]),
  ),
});

const ZOnboardingFilter = z.union([
  ZCodeFilter,
  ZTargetAreaFilter,
  ZCompleteTargetFilter,
  ZIncompleteTargetFilter,
  ZNotIncompleteTargetFilter,
]);

const ZMonthOfBirthFilter = z.object({
  monthOfBirth: z.array(
    z.enum(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']),
  ),
});

const ZNottableOnboardingFilter = ZOnboardingFilter.or(
  z.object({ not: ZOnboardingFilter }),
);

const ZOneOrMinusOne = z.literal(1).or(z.literal(-1));
const ZSort = z.object({
  numericValue: ZOneOrMinusOne,
});
const ZCodeSort = z.union([
  ZCodeFilter,
  z.object({
    sort: ZSort,
  }),
  z.object({
    and: z.array(ZOnboardingFilter),
  }),
]);

const ZPrioritisationRule = z.union([
  ZCodeSort.or(ZOnboardingFilter),
  z.object({
    and: z.array(ZCodeSort.or(ZOnboardingFilter)),
  }),
  z.object({
    or: z.array(ZCodeSort.or(ZOnboardingFilter)),
  }),
]);

export const ZMatchRule = z.union([
  ZNottableOnboardingFilter,
  ZMonthOfBirthFilter,
  z.object({
    and: z.array(z.union([ZNottableOnboardingFilter, ZMonthOfBirthFilter])),
  }),
  z.object({
    or: z.array(z.union([ZNottableOnboardingFilter, ZMonthOfBirthFilter])),
  }),
  z.object({
    or: z.array(
      z.object({
        and: z.array(z.union([ZNottableOnboardingFilter, ZMonthOfBirthFilter])),
      }),
    ),
  }),
]);

export type IMatchRule = z.infer<typeof ZMatchRule>;

export type IOnboardingFilter = z.infer<typeof ZOnboardingFilter>;
export type INottableOnboardingFilter = z.infer<
  typeof ZNottableOnboardingFilter
>;
export type IPrioritisationRule = z.infer<typeof ZPrioritisationRule>;

/// ///////
// PRACTICE ONBOARDING RULES V2
/// ///////

const ZPrioritisationSavedRule = z.object({
  id: z.string(),
});
const ZPrioritisationSavedCustomRule = z.object({
  id: z.literal('CUSTOM'),
  description: z.string(),
  rule: ZPrioritisationRule,
  additionalExtractedTargetAreas: z.array(ZTargetAreaKeys).optional(),
});

export type IPrioritisationSavedCustomRule = z.infer<
  typeof ZPrioritisationSavedCustomRule
>;

const ZPrioritisationItem = z.union([
  ZPrioritisationSavedRule,
  ZPrioritisationSavedCustomRule,
]);

export type IPrioritisationItem = z.infer<typeof ZPrioritisationItem>;

const ZPrioritisation = z.array(ZPrioritisationItem);

const ZInclusionCriteriaSavedRule = z.object({
  id: z.string(),
  options: z
    .array(z.union([z.number(), z.string()]))
    .nonempty()
    .optional(),
});
const ZInclusionCriteriaSavedCustomRule = z.object({
  id: z.literal('CUSTOM'),
  description: z.string(),
  rule: ZMatchRule,
  additionalExtractedTargetAreas: z.array(ZTargetAreaKeys).optional(),
});

export type IInclusionCriteriaSavedCustomRule = z.infer<
  typeof ZInclusionCriteriaSavedCustomRule
>;

const ZInclusionCriteriaItem = z.union([
  ZInclusionCriteriaSavedRule,
  ZInclusionCriteriaSavedCustomRule,
]);

export type IInclusionCriteriaItem = z.infer<typeof ZInclusionCriteriaItem>;

const ZInclusionCriteria = z
  .record(ZTargetAreaKeys, z.array(ZInclusionCriteriaItem))
  .refine((criteria) => Object.keys(criteria).length, {
    message: 'Inclusion criteria must have at least one target area.',
  });

const ZPracticeOnboardingLiveReferralsConfig = z.object({
  // This field is just used in onboarding and signifies the target areas that we expect to have EHR reports on for the practice
  extractedTargetAreas: z.array(ZTargetAreaKeys).nonempty(),
  // we still need the keys of inclusion criteria for live referrals to know the services agreed, but don't actually use anything inside the keys
  inclusionCriteria: ZInclusionCriteria,
  lastEditedBySuverian: ZObjectId,
  lastEditedDate: ZDateString,
  description: z.string(),
  liveReferralsOnly: z.literal(true),
});

const ZPracticeOnboardingNonLiveReferralsConfig = z.object({
  // This field is just used in onboarding and signifies the target areas that we expect to have EHR reports on for the practice
  extractedTargetAreas: z.array(ZTargetAreaKeys).nonempty(),
  inclusionCriteria: ZInclusionCriteria,
  prioritisation: ZPrioritisation,
  lastEditedBySuverian: ZObjectId,
  lastEditedDate: ZDateString,
  description: z.string(),
  liveReferralsOnly: z.literal(false),
});

export const ZPracticeOnboardingConfig = z.discriminatedUnion(
  'liveReferralsOnly',
  [
    ZPracticeOnboardingLiveReferralsConfig,
    ZPracticeOnboardingNonLiveReferralsConfig,
  ],
);

export type IPracticeOnboardingConfig = z.infer<
  typeof ZPracticeOnboardingConfig
>;
