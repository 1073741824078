import z from 'zod';
import { ZPatient } from '@suvera/core-types';

export const ZPatientRedacted = ZPatient.pick({
  _id: true,
  nhs_number: true,
  name: true,
  date_of_birth: true,
});

export type IPatientRedacted = z.infer<typeof ZPatientRedacted>;
