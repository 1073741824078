import { Navigate, Route, Routes } from 'react-router-dom';

import { RequirePartner } from 'App/PartnerAuth';
import {
  Auth0CallbackComponent,
  Logout,
  RequireAuth,
} from 'infrastructure/auth';
import { SentryTestComponent } from 'infrastructure/sentry';
import CreateReferralPage from 'pages/CreateReferralPage';
import NotFoundPage from 'pages/NotFoundPage';
import PastReferralsPage from 'pages/PastReferralsPage';
import PatientViewPage from 'pages/PatientViewPage';
import PlannerListPage from 'pages/PlannerListPage';

import { ROUTES } from './routes';

const PartnerDashboardRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <RequireAuth>
            <RequirePartner>
              <Navigate to={ROUTES.your_patients} />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.create_referral}
        element={
          <RequireAuth>
            <RequirePartner>
              <CreateReferralPage />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.past_referrals}
        element={
          <RequireAuth>
            <RequirePartner>
              <PastReferralsPage />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.find_a_patient}
        element={
          <RequireAuth>
            <RequirePartner>
              <Navigate to={ROUTES.your_patients} />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.planner}
        element={
          <RequireAuth>
            <RequirePartner>
              <Navigate to={ROUTES.your_patients} />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.your_patients}
        element={
          <RequireAuth>
            <RequirePartner>
              <PlannerListPage />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.suvera_patients}
        element={
          <RequireAuth>
            <RequirePartner>
              <PlannerListPage />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.planner_patient}
        element={
          <RequireAuth>
            <RequirePartner>
              <PatientViewPage />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route path={ROUTES.logout} element={<Logout />} />
      <Route path="/__error" element={<SentryTestComponent />} />
      <Route path="/auth0" element={<Auth0CallbackComponent />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default PartnerDashboardRoutes;
