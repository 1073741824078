import { z } from 'zod';
import { ZObjectId } from './objectid';
import { ZPatientId } from './patient';

const ZSuggestion = z.object({
  _id: ZObjectId,
  duration: z.number(),
  appointmentStartTime: z.date(),
  acuityCalendarId: z.string(),
  acuityAppointmentTypeId: z.string(),
  acuityAppointmentTypeName: z.string(),
});

const ZOutcomeBooked = z.object({
  status: z.literal('booked'),
  datetime: z.date(),
  acuityAppointmentId: z.string(),
});
export const ZDeclineAppointmentReason = z.enum([
  'need_longer_duration',
  'book_with_different_ctm',
  'patient_unavailable',
  'other',
]);
const ZOutcomeDeclined = z.object({
  status: z.literal('declined'),
  reason: ZDeclineAppointmentReason,
  datetime: z.date(),
});
const ZOutcome = z.discriminatedUnion('status', [
  ZOutcomeBooked,
  ZOutcomeDeclined,
]);

export const ZAppointmentReason = z.enum([
  'blood_pressure',
  'lipids',
  'hba1c',
  'asthma',
  'smr',
  'frailty',
  'other',
]);
export type EAppointmentReason = z.infer<typeof ZAppointmentReason>;

export const ZAppointmentSuggestion = z.object({
  _id: ZObjectId,
  patient: ZPatientId,
  suveraAppointmentType: z.literal('review'),
  suggestions: z.array(ZSuggestion),
  reasons: z.array(ZAppointmentReason),
  note: z.string().default(''),
  suverian: ZObjectId,
  outcome: ZOutcome.nullable(),
  interpreterRequired: z.boolean(),
  datetime: z.date(),
});

export type IAppointmentSuggestion = z.infer<typeof ZAppointmentSuggestion>;
