import {
  IPartnerPatientDetails,
  ZPartnerPatientDetails,
} from '@suvera/api-types';
import axios, { AxiosError } from 'axios';
import { useAuthenticatedAxios } from 'infrastructure/suveraApi';
import { useQuery } from 'react-query';
import { z } from 'zod';

const getPatientsByNhsNumber = z.object({
  result: z.array(ZPartnerPatientDetails),
});

type GetPatientsByNhsNumberResponse = z.infer<typeof getPatientsByNhsNumber>;

export const useGetPatientByNhsNumber = (nhsNumber: string) => {
  const suveraAxiosInstance = useAuthenticatedAxios();
  return useQuery({
    queryKey: ['patients', nhsNumber],
    queryFn: async (): Promise<IPartnerPatientDetails | undefined> => {
      return suveraAxiosInstance
        .get<GetPatientsByNhsNumberResponse>(
          `/patients?nhs_number=${nhsNumber}`,
        )
        .then(
          (response) => getPatientsByNhsNumber.parse(response.data).result[0],
        );
    },
    enabled: false,
    retry: false,
    onError: (error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        return;
      }
      throw error;
    },
  });
};
