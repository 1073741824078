import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

interface CopyButtonProps {
  copyText: string;
  ariaLabel?: string;
  onClick: () => void;
}

export const CopyButton = ({
  copyText,
  ariaLabel,
  onClick,
}: CopyButtonProps): ReactElement => {
  const [isCopying, setButtonTransitionState] = useState<boolean>(false);

  useEffect(() => {
    if (isCopying) {
      const t = setTimeout(() => {
        setButtonTransitionState(false);
      }, 2000);
      return () => {
        clearTimeout(t);
      };
    }
    return () => {};
  }, [isCopying]);

  const handleClick = async () => {
    setButtonTransitionState(true);
    await navigator.clipboard.writeText(copyText);
    onClick();
  };

  return (
    <IconButton
      aria-label={ariaLabel}
      onClick={handleClick}
      sx={{
        color: isCopying ? 'default' : 'colors.blue',
      }}
    >
      <ContentCopyIcon sx={{ fontSize: '16px' }} />
    </IconButton>
  );
};
