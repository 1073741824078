import { ZodFirstPartyTypeKind, z } from 'zod';

import { ZName } from './name';
import { ZObjectId } from './objectid';

import { ZDateString } from './date';
import { ZAddress } from './child/address';
import { ZPhoneNumber } from './child/phoneNumber';
import { ZTargetAreaLog, ZTargetAreasPatient } from './targets';
import { ZRegistrationMetadata } from './registrationMetadata';
import { ZEhrHistory } from './ehrStatus';

export const ZPatientId = z.string().regex(/^\d{10}$/);
export const ZNhsNumber = z.string().regex(/^\d{10}$/);
export const ZGender = z.enum(['Male', 'Female']);

export const ZEntryObjectNumericValue = z.preprocess(
  (value): unknown => {
    if (typeof value !== 'string') return value;
    if (value === null) return undefined;

    const sanitisedValue = value.replace('>=', '').replace('>', '');
    const parsed = Number.parseFloat(sanitisedValue);
    if (Number.isNaN(parsed)) return undefined;
    return parsed;
  },
  z.number(),
  {
    description: ZodFirstPartyTypeKind.ZodNumber,
  },
);

export const ZEntryObject = z.object({
  snomed: z.string().optional(),
  readCode: z.string().optional(),
  metric: z.string(),
  description: z.string(),
  datetime: ZDateString,
  note: z.string().optional(),
  value: z.string().optional(),
  numericValue: ZEntryObjectNumericValue.optional(),
  units: z.string().optional(),
  ehrUser: z.string().optional(),
});

const ZEhrExtractedCodedEntries = z.object({
  lastSynced: ZDateString,
  entries: z.array(ZEntryObject),
});

export const ZAlert = z.object({
  _id: ZObjectId,
  value: z.string(),
});
export type IAlert = z.infer<typeof ZAlert>;

export type IEhrExtractedCodedEntries = z.infer<
  typeof ZEhrExtractedCodedEntries
>;
export type IEntryObject = z.infer<typeof ZEntryObject>;

// Change accordingly later
const ZCodedEntry = z.object({
  suveraCode: z.unknown(),
});
export type CodedEntry = z.infer<typeof ZCodedEntry>;

const ZFreeTextEntry = z.object({
  text: z.string(),
});
export type FreeTextEntry = z.infer<typeof ZFreeTextEntry>;

const ZPMHxEntry = z.union([ZCodedEntry, ZFreeTextEntry]);
export const ZPastMedicalHistory = z.array(ZPMHxEntry);
export type PastMedicalHistory = z.infer<typeof ZPastMedicalHistory>;

const ZPatientCondition = z.enum(['hypertension:diagnosed']);
export type EPatientCondition = z.infer<typeof ZPatientCondition>;

export const ZPatient = z.object({
  _id: ZPatientId,
  additionalEhrData: z.any().optional(),
  address: ZAddress.optional(),
  airtable_id: z.string().optional(),
  alerts: z.array(ZAlert).optional(),
  availability: z.string().optional(),
  conditions: z.array(z.string()).optional(),
  consent_research: z.boolean().optional(),
  consent: z.string().optional(),
  date_of_birth: ZDateString.optional(),
  date_of_death: ZDateString.optional(),
  ehrExtractedCodedEntries: ZEhrExtractedCodedEntries.optional(),
  email: z.string().optional(),
  ethnicity: z
    .object({
      readable: z.string().optional(),
      raw: z.string().optional(),
    })
    .optional(),
  gender: ZGender.optional(),
  legacy_id: z.string().optional(),
  merged_ids: z.array(z.string()).optional(),
  name: ZName,
  nhs_number: ZNhsNumber,
  other_info: z.string().optional(),
  pastMedicalHistory: z.array(ZFreeTextEntry).optional(),
  phone_numbers: z.array(ZPhoneNumber),
  practice: ZObjectId,
  preferred_language: z.string().optional(),
  targetAreas: ZTargetAreasPatient.optional(),
  defaultTargetAreaState: ZTargetAreaLog.optional(),
  created_date: ZDateString,
  interpreter_required: z.boolean().optional(),
  registration_metadata: z.array(ZRegistrationMetadata).optional(),
  ehr_history: z.array(ZEhrHistory).optional(),
});

export type IPatient = z.infer<typeof ZPatient>;
