import { useEffect } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { ROUTES } from 'App/routes';
import Avo from './Avo';

const routePaths = Object.entries(ROUTES).map(([name, path]) => ({
  path,
  name,
}));

const useCurrentPageName = () => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routePaths, location);

  if (!matchedRoutes || matchedRoutes.length === 0) return null;

  return matchedRoutes[0].route.name;
};

export default function PageTracker() {
  const pageName = useCurrentPageName();

  useEffect(() => {
    if (pageName) {
      Avo.pageViewed({
        customDestinationPageName_: pageName,
      });
    }
  }, [pageName]);

  return null;
}
