import { TargetAreaKey, TargetAreaName } from '@suvera/core-types';
import { ZodObject, z } from 'zod';

export type TargetAreaStatusUpdatePropsPayloadTargetAreas =
  | TargetAreaName[]
  | 'ALL'
  | 'INVALID_FOR_TESTING';
export interface TargetAreaStatusUpdatePropsPayload {
  targetAreas?: TargetAreaStatusUpdatePropsPayloadTargetAreas;
  reason?: string;
  expiryDate?: string;
  note?: string;
  defaultTargetAreaState?: boolean;
  shouldSendSMS?: boolean;
}

type IDischargeFieldsUI = {
  label: string;
  placeholder?: string;
};
export type SelectMenuItem = {
  label: string;
  header?: boolean;
  value: string | string[] | string[][] | undefined;
};
export type PatientsTargetAreasResponseStatuses = Partial<
  Record<
    TargetAreaKey,
    {
      state: string;
      stateLabel: string;
      reason: string;
      reasonLabel: string;
      reasonGroup: string;
    }
  >
>;
export type PatientsTargetAreasResponse = {
  dischargeSummary: string;
  targetAreasItems: SelectMenuItem[];
  targetAreasDischarged: SelectMenuItem[];
  targetAreasStatuses: PatientsTargetAreasResponseStatuses;
  lastUpdated: string;
};
type PatientsTargetAreasTimelineResponseItemUpdate = {
  targetAreas: SelectMenuItem[];
  previousState: string;
  newState: string;
};
export type PatientsTargetAreasTimelineResponseItem = {
  updates: PatientsTargetAreasTimelineResponseItemUpdate[];
  suverian: {
    _id: string;
    name: {
      first: string;
      last: string;
    };
    active: boolean;
    role: string;
  };
  note?: string;
  reason: string;
  createdDate: Date;
  expiryDate?: Date;
  defaultTargetAreaState?: boolean;
  targetAreas?: string[];
};
export type PatientsTargetAreasTimelineResponse = {
  timeline: PatientsTargetAreasTimelineResponseItem[];
};

export type PatientsTargetAreasDeleteResponse = {
  status: number;
  itemsDeleted: unknown;
};
export type IDischargeFieldOLD = {
  default: unknown;
  editable: 'no' | 'optional' | 'required';
  options?: SelectMenuItem[];
  ui?: IDischargeFieldsUI;
};

export type IStatusFields = {
  expiryDate?: IStatusField;
  defaultTargetAreaState?: IStatusField;
  note?: IStatusField;
  targetAreas?: IStatusField;
};

export type IStatusField = {
  options?: SelectMenuItem[];
  label?: string;
  placeholder?: string;
};
export type IStatusReason = {
  reason?: string;
  state: string;
  displayName: string;
  reasonGroup: 'patient' | 'clinical' | 'practice' | 'manual';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation: ZodObject<any>;
  fields?: IStatusFields;
};

const expiryDateItemUntilQof: SelectMenuItem = {
  label: 'Until next QOF',
  value: 'until_next_qof_year',
};
const expiryDateItemUnitCustom: SelectMenuItem = {
  label: 'Custom date',
  value: 'custom',
};
const expiryDateItemPermanently: SelectMenuItem = {
  label: 'Permanently',
  value: 'permanently',
};

const fieldNote: IStatusField = {
  label: 'Please provide more information',
};

const fieldDefaultTargetAreaState: IStatusField = {
  label: 'Discharge from all future target areas',
};

const fieldExpiryDate: IStatusField = {
  label: 'Reactivation date',
};
const fieldTargetAreas: IStatusField = {
  label: 'Target area',
};
const setZodField = (value: unknown) => z.preprocess(() => value, z.any());

const ZTargetAreas = z
  .string()
  .array()
  .min(1, 'Target Areas must have at least one item');

const ZNoteOptional = z.string().optional();
const ZDefaultTargetAreaStateOptional = z.boolean().default(false).optional();
const ZExpiryDateSelect = z.string();
export const STATUS_REASONS: IStatusReason[] = [
  {
    state: 'discharged',
    reason: 'is_being_managed_by_gp',
    displayName: 'Managed by GP',
    reasonGroup: 'clinical',
    validation: z.object({
      defaultTargetAreaState: setZodField(false),
      expiryDate: setZodField('permanently'),
      targetAreas: ZTargetAreas,
      note: ZNoteOptional,
    }),
    fields: {
      targetAreas: fieldTargetAreas,
    },
  },
  {
    state: 'discharged',
    reason: 'is_being_managed_by_a_specialist',
    displayName: 'Managed by specialist',
    reasonGroup: 'clinical',
    validation: z.object({
      defaultTargetAreaState: setZodField(false),
      expiryDate: setZodField('permanently'),
      note: ZNoteOptional,
      targetAreas: ZTargetAreas,
    }),
    fields: {
      targetAreas: fieldTargetAreas,
      note: fieldNote,
    },
  },
  {
    state: 'discharged',
    reason: 'clinically_excluded',
    displayName: 'Clinically excluded',
    reasonGroup: 'clinical',
    validation: z.object({
      defaultTargetAreaState: ZDefaultTargetAreaStateOptional,
      expiryDate: ZExpiryDateSelect,
      note: ZNoteOptional,
      targetAreas: ZTargetAreas,
    }),
    fields: {
      targetAreas: fieldTargetAreas,
      note: fieldNote,
      defaultTargetAreaState: fieldDefaultTargetAreaState,
      expiryDate: {
        ...fieldExpiryDate,
        options: [
          expiryDateItemUntilQof,
          expiryDateItemPermanently,
          expiryDateItemUnitCustom,
        ],
      },
    },
  },

  {
    state: 'discharged',
    reason: 'refuses_diagnostics',
    displayName: 'Refuses diagnostics',
    reasonGroup: 'clinical',
    validation: z.object({
      defaultTargetAreaState: setZodField(false),
      expiryDate: setZodField('permanently'),
      note: ZNoteOptional,
      targetAreas: ZTargetAreas,
    }),
    fields: {
      targetAreas: fieldTargetAreas,
      note: {
        ...fieldNote,
        placeholder: '',
      },
    },
  },

  {
    state: 'discharged',
    reason: 'refuses_treatment',
    displayName: 'Refuses treatment',
    reasonGroup: 'clinical',
    validation: z.object({
      defaultTargetAreaState: setZodField(false),
      expiryDate: setZodField('permanently'),
      note: ZNoteOptional,
      targetAreas: ZTargetAreas,
    }),
    fields: {
      targetAreas: fieldTargetAreas,
      note: fieldNote,
    },
  },
  {
    state: 'discharged',
    reason: 'deceased',
    displayName: 'Deceased',
    reasonGroup: 'clinical',
    validation: z.object({
      targetAreas: setZodField('ALL'),
      defaultTargetAreaState: setZodField(true),
      note: ZNoteOptional,
    }),
    fields: {
      note: fieldNote,
    },
  },
  {
    state: 'discharged',
    reason: 'did_not_engage',
    displayName: 'Did not engage',
    reasonGroup: 'patient',
    validation: z.object({
      defaultTargetAreaState: setZodField(false),
      expiryDate: setZodField('until_next_qof_year'),
      note: ZNoteOptional,
      targetAreas: ZTargetAreas,
    }),
    fields: {
      targetAreas: fieldTargetAreas,
      note: fieldNote,
    },
  },
  {
    state: 'discharged',
    reason: 'abusive',
    displayName: 'Patient was abusive',
    reasonGroup: 'patient',
    validation: z.object({
      defaultTargetAreaState: setZodField(true),
      expiryDate: setZodField('permanently'),
      note: ZNoteOptional,
      targetAreas: ZTargetAreas,
    }),
    fields: {
      targetAreas: fieldTargetAreas,
      note: fieldNote,
      defaultTargetAreaState: fieldDefaultTargetAreaState,
    },
  },
  {
    state: 'discharged',
    reason: 'unhappy_with_suvera',
    displayName: 'Unhappy with Suvera',
    reasonGroup: 'patient',
    validation: z.object({
      defaultTargetAreaState: ZDefaultTargetAreaStateOptional,
      expiryDate: setZodField('permanently'),
      note: ZNoteOptional,
      targetAreas: ZTargetAreas,
    }),
    fields: {
      defaultTargetAreaState: fieldDefaultTargetAreaState,
      note: fieldNote,
      targetAreas: fieldTargetAreas,
    },
  },
  {
    state: 'discharged',
    reason: 'not_interested_in_using_suvera',
    displayName: 'Not interested in using Suvera',
    reasonGroup: 'patient',
    validation: z.object({
      defaultTargetAreaState: ZDefaultTargetAreaStateOptional,
      expiryDate: setZodField('permanently'),
      note: ZNoteOptional,
      targetAreas: ZTargetAreas,
    }),
    fields: {
      defaultTargetAreaState: fieldDefaultTargetAreaState,
      note: fieldNote,
      targetAreas: fieldTargetAreas,
    },
  },
  {
    state: 'discharged',
    reason: 'practice_unaware_of_suvera',
    displayName: 'Practice unaware of Suvera',
    reasonGroup: 'patient',
    validation: z.object({
      defaultTargetAreaState: ZDefaultTargetAreaStateOptional,
      expiryDate: setZodField('until_next_qof_year'),
      note: ZNoteOptional,
      targetAreas: ZTargetAreas,
    }),
    fields: {
      defaultTargetAreaState: fieldDefaultTargetAreaState,
      note: fieldNote,
      targetAreas: fieldTargetAreas,
    },
  },
  {
    state: 'discharged',
    reason: 'language_barrier',
    displayName: 'Language barrier',
    reasonGroup: 'patient',
    validation: z.object({
      defaultTargetAreaState: setZodField(true),
      targetAreas: setZodField('ALL'),
      expiryDate: setZodField('permanently'),
      note: ZNoteOptional,
    }),
    fields: {
      note: fieldNote,
    },
  },
  {
    state: 'discharged',
    reason: 'extended_holiday',
    displayName: 'Extended holiday',
    reasonGroup: 'patient',
    validation: z.object({
      defaultTargetAreaState: setZodField(false),
      expiryDate: ZExpiryDateSelect,
      note: ZNoteOptional,
      targetAreas: setZodField('ALL'),
    }),
    fields: {
      note: fieldNote,
      expiryDate: {
        ...fieldExpiryDate,
        options: [
          expiryDateItemUntilQof,
          expiryDateItemPermanently,
          expiryDateItemUnitCustom,
        ],
      },
    },
  },
  {
    state: 'discharged',
    reason: 'left_gp_practice',
    displayName: 'Left GP practice',
    reasonGroup: 'patient',
    validation: z.object({
      defaultTargetAreaState: setZodField(true),
      targetAreas: setZodField('ALL'),
      note: ZNoteOptional,
      expiryDate: setZodField('permanently'),
    }),
    fields: {
      note: fieldNote,
    },
  },
  {
    state: 'discharged',
    reason: 'no_mobile_number',
    displayName: 'No mobile number',
    reasonGroup: 'patient',
    validation: z.object({
      defaultTargetAreaState: setZodField(true),
      targetAreas: setZodField('ALL'),
      note: ZNoteOptional,
      expiryDate: setZodField('permanently'),
    }),
    fields: {
      note: fieldNote,
    },
  },
  {
    state: 'discharged',
    reason: 'does_not_have_equipment',
    displayName: `Doesn't have correct equipment`,
    reasonGroup: 'patient',
    validation: z.object({
      expiryDate: setZodField('until_next_qof_year'),
      defaultTargetAreaState: setZodField(false),
      targetAreas: ZTargetAreas,
      note: ZNoteOptional,
    }),
    fields: {
      targetAreas: fieldTargetAreas,
      note: {
        label: `Please outline what equipment they are missing.`,
      },
    },
  },
  {
    state: 'discharged',
    reason: 'practice_offboarded_for_target_area',
    displayName: `Practice off boarded for target area`,
    reasonGroup: 'practice',
    validation: z.object({
      defaultTargetAreaState: setZodField(false),
      expiryDate: setZodField('permanently'),
      note: ZNoteOptional,
      targetAreas: ZTargetAreas,
    }),
    fields: {
      targetAreas: fieldTargetAreas,
      note: fieldNote,
      defaultTargetAreaState: fieldDefaultTargetAreaState,
    },
  },
  {
    state: 'discharged',
    reason: 'practice_left_suvera',
    displayName: `Practice left Suvera`,
    reasonGroup: 'practice',
    validation: z.object({
      defaultTargetAreaState: setZodField(true),
      targetAreas: setZodField('ALL'),
      note: ZNoteOptional,
      expiryDate: setZodField('permanently'),
    }),
    fields: {
      note: fieldNote,
    },
  },
];

export const ACTIVE_REASON: IStatusReason = {
  state: 'active',
  displayName: `Active`,
  reasonGroup: 'manual',
  validation: z.object({
    targetAreas: z.string().array(),
    note: ZNoteOptional,
  }),
  fields: {
    targetAreas: {
      label: 'Activate target areas',
    },
    note: fieldNote,
  },
};

export const SUVERA_COMPLETE_REASON: IStatusReason = {
  state: 'suvera_complete',
  displayName: `Mark as ready for next QOF year`,
  reasonGroup: 'manual',
  validation: z.object({
    targetAreas: z.string().array(),
    note: ZNoteOptional,
  }),
  fields: {
    targetAreas: {
      label: 'Target areas',
    },
    note: fieldNote,
  },
};

export const STATUS_REASONS_BY_REASON_KEY: { [key: string]: IStatusReason } =
  // eslint-disable-next-line unicorn/no-array-reduce
  STATUS_REASONS.reduce(
    (acc: { [key: string]: IStatusReason }, reason) => {
      if (reason.reason) {
        acc[reason.reason] = reason;
      }
      return acc;
    },
    {} as { [key: string]: IStatusReason },
  );
/**
 * Provides services for the target areas reasons (all the options and business logic
 * for a target area reason that can be used to discharge/activate a patient)
 */
export const TargetAreasReasons = {
  get getReasons(): IStatusReason[] {
    return STATUS_REASONS;
  },

  getReason(payload: TargetAreaStatusUpdatePropsPayload): IStatusReason {
    if (!payload.reason) {
      throw new Error('Invalid reason / no activate flag passed in.');
    }
    if (payload.reason === 'active') {
      return ACTIVE_REASON;
    }
    if (payload.reason === 'suvera_complete') {
      return SUVERA_COMPLETE_REASON;
    }
    const reason = TargetAreasReasons.getReasons.find(
      (r) => r.reason === payload.reason,
    );

    if (!reason) {
      // eslint-disable-next-line no-console
      console.error(
        'Code:782cfdd0. Error getting reason for payload.reason',
        payload.reason,
      );
      throw new Error('Invalid reason. Code: 782cfdd0');
    }

    return reason;
  },

  validateReason(
    reason: IStatusReason,
    payload: TargetAreaStatusUpdatePropsPayload,
  ): TargetAreaStatusUpdatePropsPayload {
    if (!reason.validation) {
      return payload;
    }

    const output = reason.validation.parse(
      payload,
    ) as TargetAreaStatusUpdatePropsPayload;
    return output;
  },
};
