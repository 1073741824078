import { Close, Update } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Avo } from 'infrastructure/analytics';
import { useState } from 'react';
import { displayFormattedDate } from 'utils/displayFormattedDate';

import { useLastSyncedDate } from '../hooks/useLastSyncedDate';

const LastSyncedBanner = () => {
  const lastSyncedDate = useLastSyncedDate();
  const formattedDate = displayFormattedDate(lastSyncedDate);
  const [isBannerVisible, setIsBannerVisible] = useState(
    sessionStorage.getItem('plannerLastUpdatedBannerDismissed') !== 'true',
  );

  const handleDismissBanner = () => {
    setIsBannerVisible(false);
    sessionStorage.setItem('plannerLastUpdatedBannerDismissed', 'true');
    Avo.plannerLastUpdatedBannerClosed();
  };

  const bannerText = lastSyncedDate
    ? `This data updates every two weeks: last updated on ${formattedDate}`
    : 'This data updates every two weeks';

  return (
    <>
      {isBannerVisible && (
        <Box sx={{ top: 0, left: 0, right: 0 }}>
          <Stack
            sx={{
              height: '40px',
              backgroundColor: 'colors.blueLight',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingLeft: '42px',
              paddingRight: '8px',
            }}
            direction="row"
            spacing="4px"
          >
            <Stack
              direction="row"
              spacing="4px"
              alignItems="center"
              justifyContent="center"
              flex={1}
            >
              <Update
                style={{
                  height: '20px',
                  width: '20px',
                }}
              />
              <Typography variant="body1">{bannerText}</Typography>
            </Stack>

            <IconButton
              aria-label="dismiss banner"
              size="small"
              onClick={handleDismissBanner}
              sx={{
                color: 'text.primary',
              }}
            >
              <Close
                style={{
                  height: '20px',
                  width: '20px',
                }}
              />
            </IconButton>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default LastSyncedBanner;
