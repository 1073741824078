import { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Select,
  Stack,
  Typography,
  FormControl,
  MenuItem,
  Divider,
  Button,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { TargetAreaStatusReason } from '@suvera/core-types/types/targets';
import { Avo } from 'infrastructure/analytics';
import { DischargeReasonValueType } from 'infrastructure/analytics/Avo';

const patientOptOutReasons: {
  value: TargetAreaStatusReason;
  label: string;
}[] = [
  { value: 'deceased', label: 'Patient is deceased' },
  {
    value: 'not_interested_in_using_suvera',
    label: "Patient doesn't want to use an online service",
  },
  {
    value: 'does_not_have_equipment',
    label: "Patient doesn't have the right equipment",
  },
  {
    value: 'extended_holiday',
    label: 'Patient is going on an extended holiday',
  },
  {
    value: 'is_being_managed_by_a_specialist',
    label: 'Patient is being managed by a specialist',
  },
  {
    value: 'is_being_managed_by_gp',
    label: 'Patient is being managed by their GP',
  },
  { value: 'language_barrier', label: 'Patient doesn’t speak English' },
  { value: 'left_gp_practice', label: 'Patient has left the GP practice' },
  {
    value: 'medical',
    label: 'Patient has another medical condition',
  },
  { value: 'moved_away', label: 'Moved away' },
  { value: 'no_mobile_number', label: "Patient doesn't have a mobile phone" },
  {
    value: 'not_interested_in_using_suvera',
    label: 'Not interested in using Suvera',
  },
  {
    value: 'unhappy_with_suvera',
    label: "Patient is unhappy with the care they've received",
  },
];

type Props = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (dischargeReason: TargetAreaStatusReason) => void;
  patientName: string;
};

const DischargePatientForm = ({
  open,
  handleClose,
  handleSubmit,
  patientName,
}: Props) => {
  const [selectedDischargeReason, setSelectedDischargeReason] = useState<
    TargetAreaStatusReason | ''
  >('');

  const handleModalClose = () => {
    handleClose();
    setSelectedDischargeReason('');
    Avo.patientViewDischargeModalClosed();
  };

  const handleDischargeRequest = async () => {
    if (!selectedDischargeReason) {
      return;
    }
    handleSubmit(selectedDischargeReason);
    Avo.patientViewDischargeReasonSubmitButtonClicked();
    handleClose();
    setSelectedDischargeReason('');
  };

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      data-testid="discharge_planner_modal"
      maxWidth="xs"
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle
          sx={{ fontSize: '16px', fontWeight: 600, paddingX: '16px' }}
        >
          Discharge
        </DialogTitle>

        <Box paddingX="16px">
          <IconButton
            onClick={handleModalClose}
            size="medium"
            aria-label="Close modal"
            sx={{ color: 'colors.black' }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </Box>
      </Stack>
      <DialogContent
        sx={{ paddingTop: '0px', paddingX: '16px', paddingBottom: '16px' }}
      >
        <Stack spacing="4px" data-testid="patient_discharge">
          <Typography variant="body1" color="colors.greyDark">
            {`Please select the reason you’d like to discharge ${patientName} from Suvera`}
            <span style={{ color: '#da2d2d' }}>{'*'}</span>
          </Typography>
          <FormControl>
            <Select
              value={selectedDischargeReason}
              onChange={(event) => {
                setSelectedDischargeReason(
                  event.target.value as TargetAreaStatusReason,
                );
                Avo.patientViewDischargeReasonSelected({
                  dischargeReason: event.target
                    .value as DischargeReasonValueType,
                });
              }}
              displayEmpty
              aria-label="Discharge reason"
            >
              <MenuItem disabled value="">
                Select option
              </MenuItem>
              {patientOptOutReasons.map((reason) => (
                <MenuItem value={reason.value} key={reason.label}>
                  {reason.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <Divider />
      <Stack padding="16px" sx={{ alignItems: 'flex-end' }}>
        <Button
          variant="contained"
          disabled={!selectedDischargeReason}
          onClick={handleDischargeRequest}
        >
          Submit
        </Button>
      </Stack>
    </Dialog>
  );
};

export default DischargePatientForm;
