import { z } from 'zod';
import { ZDateString } from './date';
import { ZObjectId } from './objectid';

const ZCancellationReason = z.enum([
  'discharged_from_all_target_areas',
  'fully_target_complete',
]);
export type CancellationReason = z.infer<typeof ZCancellationReason>;

export const ZAppointment = z.object({
  _id: z.string(),
  patient: z.object({
    _id: z.string(),
    name: z.object({
      first: z.string(),
      last: z.string(),
    }),
    email: z.string(),
    phone: z.string(),
  }),
  datetime: ZDateString,
  datetime_created: ZDateString,
  formatted_datetime: z.string(),
  duration: z.number(),
  suverian: z.object({
    name: z.string(),
    calendar_id: z.string(),
  }),
  type: z.object({
    name: z.string(),
    type_id: z.string(),
  }),
  cancelled: z.boolean(),
  rescheduling_link: z.string(),
  note: z.string().optional(),
  reasons: z.array(z.string()).optional(),
  cancellation_reason: ZCancellationReason.optional(),
});
export type IAppointment = z.infer<typeof ZAppointment>;

export const ZAppointmentEvent = z.object({
  _id: z.string(),
  appointment: z.object({
    _id: z.string(),
    type: z.string(),
    suverian: z.object({
      calendar: z.string(),
    }),
    patient: z.string(),
    created: ZDateString,
    datetime: ZDateString,
    formattedDatetime: z.string(),
    duration: z.number(),
    cancelled: z.boolean(),
  }),
  datetime: ZDateString,
  type: z.string(),
});
export type IAppointmentEvent = z.infer<typeof ZAppointmentEvent>;

export const ZCalendar = z.object({
  _id: z.string(),
  email: z.string(),
  suverian: z.object({
    name: z.object({
      first: z.string(),
      last: z.string(),
    }),
  }),
});
export type ICalendar = z.infer<typeof ZCalendar>;

export const ZAppointmentSlot = z.object({
  calendar: z.string(),
  appointment_type: z.string(),
  datetime: z.date(),
});
export type IAppointmentSlot = z.infer<typeof ZAppointmentSlot>;

export const ZAcuityAppointmentSlot = z.object({
  calendarID: z.string(),
  appointmentTypeID: z.string(),
  datetime: z.date(),
});
export type IAcuityAppointmentSlot = z.infer<typeof ZAcuityAppointmentSlot>;

const ZAcuityAppointmentSlotValidated = z
  .object({
    datetime: z.string(),
    appointmentTypeID: z.number(),
    calendarID: z.number(),
    valid: z.boolean(),
  })
  .transform((slot) => ({
    ...slot,
    calendarID: slot.calendarID.toString(),
    appointmentTypeID: slot.appointmentTypeID.toString(),
  }));
export type IAcuityAppointmentSlotValidated = z.infer<
  typeof ZAcuityAppointmentSlotValidated
>;

export const ZValidSlots = z.array(ZAcuityAppointmentSlotValidated);

const ZAcuityAppointmentType = z.object({
  id: z.number(),
  active: z.boolean(),
  name: z.string(),
  duration: z.number(),
  calendarIDs: z.array(z.number()),
});
export type AcuityAppointmentType = z.infer<typeof ZAcuityAppointmentType>;

const ZAppointmentType = ZAcuityAppointmentType.omit({
  id: true,
}).extend({
  _id: z.string(),
});
export type IAppointmentType = z.infer<typeof ZAppointmentType>;

const ZAcuityAppointment = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string(),
  datetime: z.string(),
  datetimeCreated: z.string(),
  dateCreated: z.string(),
  duration: z.string(),
  calendar: z.string(),
  calendarID: z.number(),
  type: z.string(),
  appointmentTypeID: z.number(),
  canceled: z.boolean(),
  confirmationPage: z.string(),
  location: z.string(),
  time: z.string().optional(),
  date: z.string().optional(),
  forms: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      values: z.array(
        z.object({
          id: z.number(),
          fieldID: z.number(),
          value: z.string(),
          name: z.string(),
        }),
      ),
    }),
  ),
  notes: z.string().optional(),
  reasons: z.array(z.string()).optional(),
});
export type IAcuityAppointment = z.infer<typeof ZAcuityAppointment>;

const ZPatientAppointmentChange = z.object({
  event: z.union([
    z.literal('CREATED'),
    z.literal('RESCHEDULED'),
    z.literal('CANCELLED'),
    z.literal('UPDATED'),
  ]),
  datetime: z.date(),
  appointmentDatetime: z.date().optional(),
  note: z.string().optional(),
  acuityAppointmentTypeId: z.string().optional(),
  acuityAppointmentTypeName: z.string().optional(),
  acuityCalendarId: z.string().optional(),
  reasons: z.array(z.string()).optional(),
  duration: z.number().optional(),
  cancelled: z.boolean().optional(),
});

const ZPatientAppointmentReminder = z.object({
  type: z.literal('48_hour_sms'),
  appointmentDatetime: z.date(),
  interactionId: ZObjectId,
  datetime: z.date(),
});

export const ZPatientAppointment = z.object({
  _id: ZObjectId,
  patient: z.string(),
  acuityAppointmentId: z.string(),
  acuityAppointmentTypeName: z.string(),
  acuityAppointmentTypeId: z.string(),
  appointmentDatetime: z.date(),
  createdDatetime: z.date(),
  note: z.string().optional(),
  changes: z.array(ZPatientAppointmentChange),
  acuityCalendarId: z.string(),
  reasons: z.array(z.string()).optional(),
  duration: z.number(),
  cancelled: z.boolean(),
  reminders: z.array(ZPatientAppointmentReminder).optional(),
});

export type IPatientAppointment = z.infer<typeof ZPatientAppointment>;

export type IPatientAppointmentChange = z.infer<
  typeof ZPatientAppointmentChange
>;
