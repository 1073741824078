import { Box, Button, Stack, Typography } from '@mui/material';
import { IPlannerPatient } from '@suvera/api-types';
import { TARGET_AREA_LABELS, TargetAreaKey } from '@suvera/core-types';

import { Avo } from 'infrastructure/analytics';
import { addSpacesToNhsNumber } from 'utils/addSpacesToNhsNumber';
import { displayFormattedDate } from 'utils/displayFormattedDate';
import { linkToPatientView } from 'App/routes';
import { useNavigate } from 'react-router-dom';

export const getTargetAreasToDisplay = (targetAreas: string[]) => {
  if (targetAreas.length === 0) return '-';

  return (targetAreas as TargetAreaKey[])
    .map((targetArea) => TARGET_AREA_LABELS[targetArea] || targetArea)
    .join(', ');
};

const PatientRow = ({
  nhs_number,
  name,
  date_of_birth,
  suveraLedTargetAreas,
  practiceLedTargetAreas,
}: IPlannerPatient) => {
  const patientName = name.title
    ? `${name.title} ${name.first} ${name.last}`
    : `${name.first} ${name.last}`;

  const patientDob = displayFormattedDate(date_of_birth);
  const practiceLedTargetAreaLabel = 'Practice led target areas';
  const suveraLedTargetAreaLabel = 'Suvera led target areas';

  const navigate = useNavigate();

  const handleViewPatientClick = () => {
    Avo.patientViewButtonClicked();
    return navigate(linkToPatientView(nhs_number));
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '52px',
        paddingX: '12px',
        paddingY: '8px',
      }}
      display="flex"
      alignItems="stretch"
      columnGap="12px"
      data-testid="patient-row"
    >
      <Stack flex={1}>
        <Typography fontWeight={600} variant="body2">
          {patientName}
        </Typography>
        <Stack direction="row" spacing="4px" flex-grow={1}>
          <Typography variant="body2">NHS no.</Typography>
          <Typography variant="body2" fontWeight={600}>
            {addSpacesToNhsNumber(nhs_number)}
          </Typography>
        </Stack>
      </Stack>
      <Stack flex={1} maxWidth="100px">
        <Typography variant="body2">Date of birth</Typography>
        <Typography variant="body2" fontWeight={600}>
          {patientDob}
        </Typography>
      </Stack>
      <Stack flex={1}>
        <Typography variant="body2">{practiceLedTargetAreaLabel}</Typography>
        <Typography
          variant="body2"
          fontWeight={600}
          data-testid="practice-led-target-areas"
        >
          {getTargetAreasToDisplay(practiceLedTargetAreas)}
        </Typography>
      </Stack>
      <Stack flex={1}>
        <Typography variant="body2">{suveraLedTargetAreaLabel}</Typography>
        <Typography
          variant="body2"
          fontWeight={600}
          data-testid="suvera-led-target-areas"
        >
          {getTargetAreasToDisplay(suveraLedTargetAreas)}
        </Typography>
      </Stack>
      <Stack flex="0 0 50px" justifyContent="center">
        <Button
          size="small"
          variant="outlined"
          sx={{
            minWidth: 'unset',
            borderColor: 'colors.grey',
            borderWidth: '1px',
            borderStyle: 'solid',
          }}
          onClick={() => handleViewPatientClick()}
        >
          View
        </Button>
      </Stack>
    </Box>
  );
};

export default PatientRow;
