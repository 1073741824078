import { z } from 'zod';
import { ZDateString } from './date';

import { ZObjectId } from './objectid';
import { ZPatientId } from './patient';

export const ZVideoCallEvent = z.object({
  _id: ZObjectId,
  patient: ZPatientId,
  suverian: ZObjectId,
  roomName: z.string(),
  total_call_duration: z.number().optional(),
  event_last_received_at: ZDateString,
  datetime: ZDateString,
  is_synced: z.boolean(),
});

export type IVideoCallEvent = z.infer<typeof ZVideoCallEvent>;
