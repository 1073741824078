import { SmsFailed } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';

import { usePracticeName } from 'App/PartnerAuth/requests';
import PatientSearch from 'components/PatientSearch';
import { Avo } from 'infrastructure/analytics';

import { PLANNER_FEEDBACK_FORM_URL } from '../../../consts';

import NavigationMenu from './NavigationMenu';
import NavigationTabs from './NavigationTabs';
import SuveraLogo from './SuveraLogo';

type Props = {
  handleOpenHelpModal: () => void;
};

export default function HeaderContent({ handleOpenHelpModal }: Props) {
  const practiceName = usePracticeName();

  return (
    <Stack direction="row" spacing="16px" alignItems="center" width="100%">
      <Stack direction="row" spacing="8px" alignItems="center">
        <SuveraLogo />

        <Typography
          variant="body2"
          color="colors.black"
          maxWidth={`${60 + 2.25 * practiceName.length}px`}
          fontWeight={600}
          style={{
            lineHeight: 1.25,
            fontSize: '12px',
          }}
        >
          {practiceName}
        </Typography>
      </Stack>

      <NavigationTabs />

      <Button
        LinkComponent="a"
        href={PLANNER_FEEDBACK_FORM_URL}
        target="_blank"
        rel="noopener noreferrer"
        size="small"
        variant="text"
        startIcon={<SmsFailed />}
        onClick={() => Avo.feedbackFormOpened()}
      >
        Give us feedback
      </Button>

      <PatientSearch />

      <NavigationMenu handleOpenHelpModal={handleOpenHelpModal} />
    </Stack>
  );
}
