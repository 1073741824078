import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useGetAuthenticatedPartner } from 'App/PartnerAuth/requests';
import { Avo } from 'infrastructure/analytics';
import { useState } from 'react';

export default function SuveraOnboardingRulesButton() {
  const [openOnboardingRulesModal, setOpenOnboardingRulesModal] =
    useState(false);

  const partner = useGetAuthenticatedPartner().data;

  const onboardingRulesText = partner?.practice?.onboardingRulesDescription;

  const handleOpen = () => {
    setOpenOnboardingRulesModal(true);
    Avo.onboardingRulesDescriptionModalOpened();
  };

  const handleClose = () => {
    setOpenOnboardingRulesModal(false);
    Avo.onboardingRulesDescriptionModalClosed();
  };

  return (
    <>
      <Button onClick={handleOpen} variant="outlined" size="small">
        {"Suvera's onboarding rules"}
      </Button>

      {openOnboardingRulesModal && (
        <Dialog open={openOnboardingRulesModal} onClose={handleClose}>
          <Stack direction="row" justifyContent="space-between">
            <DialogTitle component="h1" variant="h2">
              {"Suvera's onboarding rules"}
            </DialogTitle>

            <Box paddingX="24px" paddingY="16px">
              <IconButton
                onClick={handleClose}
                size="medium"
                aria-label="Close modal"
                sx={{ color: 'colors.black' }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </Box>
          </Stack>

          <DialogContent
            sx={{
              paddingTop: '0px',
              width: 'auto',
              maxWidth: '720px',
              alignItems: 'center',
              textAlign: 'justify',
            }}
          >
            <Stack spacing="16px">
              <Alert severity="info" icon={false} sx={{ padding: '0px 8px' }}>
                These rules are how we decide which patients we should onboard
                to the virtual clinic. These are decided with your practice and
                can be changed.
              </Alert>

              <Typography>{onboardingRulesText}</Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
