import { z } from 'zod';
import { ZObjectId } from '../objectid';

export const ZPhoneNumber = z.object({
  _id: ZObjectId,
  number: z.string(),
  type: z.enum(['mobile', 'landline', 'other']),
});

export type IPhoneNumber = z.infer<typeof ZPhoneNumber>;
