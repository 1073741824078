import { Search } from '@mui/icons-material';
import { Autocomplete, Button, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useState } from 'react';

import { Avo } from 'infrastructure/analytics';
import { nhsNumberSchema } from 'schemas/nhsNumbers';

import AutocompleteOption from './components/AutocompleteOption';

export default function PatientSearch() {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const { success: isValid } = nhsNumberSchema.safeParse(searchValue);

  const setDebouncedValue = debounce((value: string) => {
    setDebouncedSearchValue(value);
  }, 500);

  const isLoading = searchValue !== debouncedSearchValue;
  const showError = !isLoading && searchValue.length > 0 && !isValid;

  return (
    <>
      <Autocomplete
        freeSolo
        clearOnEscape={false}
        sx={{
          width: '250px',
          '& .MuiOutlinedInput-input.MuiAutocomplete-input': {
            paddingY: '4px',
            paddingX: '0px',
          },
        }}
        options={searchValue.length > 0 ? [searchValue] : []}
        inputValue={searchValue}
        onInputChange={(_, newInputValue) => {
          setSearchValue(newInputValue);
          setDebouncedValue(newInputValue);
        }}
        renderOption={() => (
          <AutocompleteOption
            key={searchValue}
            searchValue={searchValue}
            isLoading={isLoading}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={() => {
              Avo.findAPatientFormFieldTouched();
            }}
            placeholder="Search by NHS number"
            error={showError}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: 'colors.black' }} fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchValue && (
                    <Button
                      onClick={() => {
                        setSearchValue('');
                        setDebouncedSearchValue('');
                        Avo.findAPatientClearButtonClicked();
                      }}
                    >
                      Clear
                    </Button>
                  )}
                </InputAdornment>
              ),
              style: { padding: '0px 12px' },
            }}
          />
        )}
      />
    </>
  );
}
