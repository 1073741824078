import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { ReactElement } from 'react';

const LoadingState = (): ReactElement => (
  <Box sx={{ textAlign: 'center', paddingTop: 'calc(50vh - 110px)' }}>
    <Typography marginBottom="16px">Loading...</Typography>

    <CircularProgress />
  </Box>
);

export default LoadingState;
