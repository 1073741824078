import z from 'zod';

import { ZName } from './name';
import { ZObjectId } from './objectid';

export const ZPartner = z.object({
  _id: ZObjectId,
  name: ZName,
  email: z.string().email(),
  practices: z.array(ZObjectId),
});

export type IPartner = z.infer<typeof ZPartner>;
