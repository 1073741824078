import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { IPartnerPatientDetails } from '@suvera/api-types';
import { TargetAreaStatusReason } from '@suvera/core-types/types/targets';
import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'App/routes';
import { Avo } from 'infrastructure/analytics';
import { useAuthenticatedAxios } from 'infrastructure/suveraApi';
import { sanitiseNhsNumber } from 'schemas/nhsNumbers';

import DischargePatientForm from './DischargePatientForm';
import PlannerSnackbar from './PlannerSnackbar';

type Props = {
  patient: IPartnerPatientDetails;
};

const PatientViewHeader = ({ patient }: Props) => {
  const navigate = useNavigate();
  const [isDischargeModalOpen, setIsDischargeModalOpen] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const nhsNumber = useParams().nhsNumber || '';

  const handleBackClick = () => {
    Avo.patientViewBackButtonClicked();
    navigate(-1);
  };

  const useDischargePatient = () => {
    const suveraAxiosInstance = useAuthenticatedAxios();

    return useMutation({
      mutationFn: (dischargeReason: TargetAreaStatusReason) => {
        return suveraAxiosInstance.post(
          `/patients/${patient?.id}/discharge-request`,
          {
            type: 'json',
            dischargeReason,
          },
        );
      },
      onSuccess: () => {
        setAlertMessage('Discharge request sent to Suvera.');
        setIsError(false);
        setIsAlertShown(true);
        Avo.patientViewDischargeSuccessMessageViewed();
      },
      onError: (error: Error | AxiosError) => {
        setAlertMessage('Something went wrong. Please try again.');
        setIsError(true);
        setIsAlertShown(true);
        Avo.patientViewDischargeErrorMessageViewed();
        if (axios.isAxiosError(error) && error.response) {
          return;
        }
        captureException(error);
      },
    });
  };

  const dischargePatient = useDischargePatient();

  const handleDischargeRequest = (dischargeReason: TargetAreaStatusReason) => {
    dischargePatient.mutate(dischargeReason);
    setIsDischargeModalOpen(false);
  };

  const handleCreateReferralClick = () => {
    Avo.patientViewReferralButtonClicked();
    navigate(
      `${ROUTES.create_referral}?nhsNumber=${sanitiseNhsNumber(nhsNumber)}`,
    );
  };

  return (
    <>
      <Box
        marginBottom="16px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Button
          onClick={handleBackClick}
          startIcon={<ArrowBack />}
          variant="text"
          sx={{ width: 'fit-content' }}
          size="small"
        >
          Back
        </Button>

        <Stack spacing="8px" direction="row">
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setIsDischargeModalOpen(true);
              Avo.patientViewDischargeButtonClicked();
            }}
          >
            Discharge from Suvera
          </Button>

          <Button
            variant="contained"
            sx={{ width: 'fit-content' }}
            size="small"
            onClick={handleCreateReferralClick}
          >
            Create a referral
          </Button>
        </Stack>
      </Box>

      <DischargePatientForm
        open={isDischargeModalOpen}
        handleClose={() => setIsDischargeModalOpen(false)}
        handleSubmit={(dischargeReason: TargetAreaStatusReason) =>
          handleDischargeRequest(dischargeReason)
        }
        patientName={`${patient.name.first} ${patient.name.last}`}
      />

      <PlannerSnackbar
        isOpen={isAlertShown}
        onClose={() => setIsAlertShown(false)}
        message={alertMessage}
        isError={isError}
      />
    </>
  );
};

export default PatientViewHeader;
