export const brandColors = {
  greys: {
    ghostPurple: '#EEF1FF',
    lightSteelBlue: '#ACBBD4',
  },
  amethyst: {
    _10: '#EBE4F7',
    _20: '#6139A9',
  },
  mustards: {
    _20: '#EAA901',
    _10: '#FFF4D8',
  },
};

export const colors = {
  blue: '#246CCC',
  blueDark: '#28324D',
  blueElectric: '#0E3D89',
  blueLight: '#E3F0FD',
  black: '#0F1B38',
  grey: '#9F9F9F',
  greyDark: '#42495E',
  greyLight: '#CECECE',
  greyLightest: '#F6F6F6',
  grey20: '#FCFCFC',
  grey40: '#D0D0D0',
  grey60: '#B7B7B7',
  white: '#FFFFFF',
  red: '#DA2D2D',
  redLight: '#F5DFDF',
  amber: '#FF7A00',
  amber20: '#FF7A00',
  amberLight: '#FFE8D9',
  yellow: '#FBC400',
  yellow20: '#FFC700',
  yellowLight: '#FEF3D6',
  green: '#1E8547',
  greenLight: '#E5F5E6',
  /* DEPRECATED colours */
  DEPRECATED_purpleLight: '#8A52F2',
};

export type Colors = typeof colors;
export type BrandColors = typeof brandColors;
