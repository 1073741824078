import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { initialiseAnalytics } from 'infrastructure/analytics';
import { RecoilRoot } from 'recoil';

import { SuveraAuth0Provider } from './infrastructure/auth';
import { initialiseSentry } from './infrastructure/sentry';
import App from './App';

const root = ReactDOM.createRoot(
  document.querySelector('#root') as HTMLElement,
);

initialiseSentry();
initialiseAnalytics();

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <CssBaseline />
      <SuveraAuth0Provider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SuveraAuth0Provider>
    </RecoilRoot>
  </React.StrictMode>,
);
