import { useGetAuthenticatedPartner } from 'App/PartnerAuth/requests';

export const usePracticeTargetAreas = () => {
  const { data: partner } = useGetAuthenticatedPartner();

  return {
    contractedTargetAreas: partner?.practice.contractedTargetAreas ?? [],
    notContractedTargetAreas: partner?.practice.notContractedTargetAreas ?? [],
  };
};
