import z from 'zod';
import { ZDateString } from './date';

export const registrationStatuses = [
  'registered',
  'deducted_deceased',
  'deducted_left',
  'deducted_other',
  'deducted_unknown',
] as const;

export const ZRegistrationStatus = z.enum(registrationStatuses);
export type RegistrationStatus = z.infer<typeof ZRegistrationStatus>;

export const ZRegistrationMetadata = z.object({
  odsCode: z.string(),
  registrationStatus: ZRegistrationStatus,
  datetime: ZDateString,
});
