import { Button, Stack, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { IPartnerPatientDetails } from '@suvera/api-types';
import { isToday } from 'date-fns';
import { format } from 'date-fns/fp';
import { useAuthenticatedAxios } from 'infrastructure/suveraApi';
import { useState } from 'react';
import { useMutation } from 'react-query';
import PlannerSnackbar from './PlannerSnackbar';

const formatDate = format('dd/MM/yyyy');
const formatTime = format('HH:mm');

const formatLastSyncDate = (lastSyncDate?: Date) => {
  if (lastSyncDate === undefined) {
    return 'Not synced yet';
  }

  return isToday(lastSyncDate)
    ? `Last synced today at ${formatTime(lastSyncDate)}`
    : `Last synced on ${formatDate(lastSyncDate)} at ${formatTime(lastSyncDate)}`;
};

export const EhrSync = ({ patient }: { patient: IPartnerPatientDetails }) => {
  const { ehrSync } = patient;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const { isPending, lastSyncDate } = ehrSync;

  const suveraAxiosInstance = useAuthenticatedAxios();

  const syncPatient = useMutation({
    mutationFn: (patientId: string) => {
      return suveraAxiosInstance.post(`/patients/${patientId}/sync`);
    },
    onSuccess: () => {
      setIsAlertShown(true);
      setAlertMessage(
        'The patient will be synced with the EHR in a few minutes.',
      );
      setIsError(false);
    },
    onError: () => {
      setIsAlertShown(true);
      setAlertMessage('Something went wrong.');
      setIsError(true);
      setIsSubmitted(false);
    },
  });

  const handleSubmit = () => {
    syncPatient.mutate(patient.id);
    setIsSubmitted(true);
  };

  const isSyncing = isPending || isSubmitted;

  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        {isSyncing ? (
          <>
            <Button
              variant="outlined"
              size="small"
              disabled
              startIcon={<SyncIcon />}
            >
              Syncing with EHR…
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            size="small"
            onClick={handleSubmit}
            startIcon={<SyncIcon />}
          >
            Sync with EHR
          </Button>
        )}

        <Typography variant="body2">
          {formatLastSyncDate(lastSyncDate)}
        </Typography>

        <PlannerSnackbar
          isOpen={isAlertShown}
          onClose={() => setIsAlertShown(false)}
          message={alertMessage}
          isError={isError}
        />
      </Stack>
    </>
  );
};
