import { SmsFailed } from '@mui/icons-material';
import { Button } from '@mui/material';

import { Avo } from 'infrastructure/analytics';

import { PLANNER_FEEDBACK_FORM_URL } from '../../../consts';

export default function FeedbackButton() {
  return (
    <Button
      LinkComponent="a"
      href={PLANNER_FEEDBACK_FORM_URL}
      target="_blank"
      rel="noopener noreferrer"
      size="small"
      variant="outlined"
      startIcon={<SmsFailed />}
      onClick={() => Avo.feedbackFormOpened()}
    >
      Give us feedback
    </Button>
  );
}
