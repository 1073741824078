import { z } from 'zod';

// eslint-disable-next-line unicorn/prefer-set-has
const templateTokens = [
  '<Patient first name>',
  '<Practice name>',
  '<Service phone number>',
  '<Service support link>',
  '<Case-finding conditions>',
  '<Non-case-finding conditions>',
];

const gsmCharacters =
  // eslint-disable-next-line unicorn/better-regex
  /^[A-Za-z0-9 @£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,\-./:;<=>?¡§¿ÄÖÑÜäöñü\n\r]*$/;

export const ZPracticeSmsTemplate = z
  .string()
  .optional()
  .refine((value) => {
    const unevenBracketsCount = (value || '')
      // eslint-disable-next-line unicorn/prefer-spread
      .split('')
      .filter((char) => char === '<' || char === '>')
      .join('')
      // eslint-disable-next-line unicorn/prefer-string-replace-all
      .replace(/<>/g, '').length;

    return unevenBracketsCount === 0;
  }, 'Every opening bracket < should have a closing one >')

  .refine(
    (value) => value === '' || value === undefined || gsmCharacters.test(value),
    'This copy contains characters not allowed in SMSes',
  )
  .refine((value) => {
    const matches = (value || '').match(/<[^>]*>/g) || [];
    return matches.every((match) => templateTokens.includes(match));
  }, 'Unsupported token used');
