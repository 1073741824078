import { ICohortDetails, cohorts } from '@suvera/api-types';

import { usePlannerParams } from './usePlannerParams';

export const useCohortFromParams = (): ICohortDetails => {
  const { cohort } = usePlannerParams();

  const currentCohort = Object.values(cohorts).find(
    ({ value }) => value === cohort,
  );

  return currentCohort!;
};
