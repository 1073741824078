import { captureException } from '@sentry/react';
import {
  IExportListResponse,
  IExportPatientListRequest,
} from '@suvera/api-types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAuthenticatedAxios } from 'infrastructure/suveraApi';
import { useQuery } from 'react-query';
import { z } from 'zod';

const getExportPatientList = z.object({
  blob: z.unknown(),
  fileName: z.string(),
});

type GetExportPatientListResponse = {
  blob: Blob;
  fileName: string;
};

export const extractFileNameFromHeaders = (
  response: AxiosResponse<IExportListResponse>,
) => {
  if (!response) throw new Error('Response was expected but not found');

  const contentDisposition = response.headers['content-disposition'] as string;

  return contentDisposition.split('filename="')[1].slice(0, -1);
};

export const useExportPatientList = (request: IExportPatientListRequest) => {
  const suveraAxiosInstance = useAuthenticatedAxios();

  return useQuery({
    queryKey: ['exportPatientList', request],
    queryFn: async (): Promise<GetExportPatientListResponse> => {
      return suveraAxiosInstance
        .post<IExportListResponse>('/planner/patients/export', request, {
          responseType: 'blob',
        })
        .then((response) => {
          return getExportPatientList.parse({
            blob: response.data,
            fileName: extractFileNameFromHeaders(response),
          }) as GetExportPatientListResponse;
        });
    },
    retry: false,
    onError: (error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        return;
      }
      captureException(error);
    },
    enabled: false,
  });
};
