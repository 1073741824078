import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

const removeUrlQuerystring = (url: string) => url.split('?')[0];

export const initialiseSentry = () => {
  if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({
          enableInp: true,
        }),
        Sentry.browserProfilingIntegration(),
        // Or, if you are using react router, use the appropriate integration
        // See docs for support for different versions of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      environment: process.env.REACT_APP_SST_STAGE || process.env.NODE_ENV,
      tracesSampleRate: 0.5,
      profilesSampleRate: 0.5,
      beforeBreadcrumb(breadcrumb, hint?) {
        if (breadcrumb.category === 'xhr') {
          const rawFetchResponse = hint?.response as Response;
          const urlInRequest = breadcrumb.data?.url as unknown;
          const cleanedUrl =
            urlInRequest && typeof urlInRequest === 'string'
              ? removeUrlQuerystring(urlInRequest)
              : '';
          const modifiedBreadcrumb = {
            ...breadcrumb,
            data: {
              ...breadcrumb.data,
              url: cleanedUrl,
              'AWS API Gateway Request ID':
                rawFetchResponse.headers.get('apigw-requestid'),
              'AWS XRAY Request ID':
                rawFetchResponse.headers.get('xray-requestid'),
              'AWS Lambda Request ID':
                rawFetchResponse.headers.get('lambda-requestid'),
              'AWS Lambda Function':
                rawFetchResponse.headers.get('lambda-function'),
              'AWS Lambda Log Stream':
                rawFetchResponse.headers.get('lambda-logstream'),
            },
          };
          return modifiedBreadcrumb;
        }
        return breadcrumb;
      },
    });
  }
};

export const SentryTestComponent = () => {
  return <p>{process.env.THIS_DOES_NOT_EXIST!.toLowerCase()}</p>;
};
