import { GenericError } from 'components/Errors';
import LoadingState from 'components/LoadingState';
import { Avo } from 'infrastructure/analytics';
import { FC, lazy, ReactElement, Suspense, useEffect } from 'react';
import { useGetAuthenticatedPartner } from '../requests';

const UnknownPartnerScreen = lazy(() => import('./UnknownPartnerScreen'));
const MaintenancePage = lazy(() => import('pages/MaintenancePage'));

export const RequirePartner: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { data: partner, isSuccess, isError } = useGetAuthenticatedPartner();
  const shouldShowMaintenance =
    partner?.featureFlags?.['partner-dashboard-maintenance'] ?? false;

  useEffect(() => {
    if (isSuccess && partner === null) {
      Avo.unknownUserScreenSeen();
    }
  }, [isSuccess, partner]);

  if (isError) {
    return <GenericError />;
  }

  if (isSuccess && partner === null) {
    return (
      <Suspense fallback={<LoadingState />}>
        <UnknownPartnerScreen />
      </Suspense>
    );
  }

  if (isSuccess && shouldShowMaintenance) {
    return <MaintenancePage />;
  }

  if (isSuccess) {
    return children;
  }

  return <LoadingState />;
};
