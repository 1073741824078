import { z } from 'zod';
import { ZBaseTask } from './task';

const ZETaskType = ZBaseTask.shape.task_type;
export type ETaskType = z.infer<typeof ZETaskType>;

export const ZTaskType = z.object({
  _id: ZETaskType,
  name: z.string(),
  description: z.string(),
  priority: z.number(),
});
export type ITaskType = z.infer<typeof ZTaskType>;
