import { z } from 'zod';

import { ZObjectId } from './objectid';
import { ZAlert, ZPatientId } from './patient';
import {
  EInteractionType,
  ZConsultationInteractionType,
  ZInteractionType,
  ZOtherInteractionType,
} from './dictionary/interactionTypeDictionary';
import {
  ZContextQuestions,
  ZTemplateStructuredContent,
} from './interaction.fields';
import { ZClinicianCompletableQuestionnaireFormType } from './questionnaire';
import { ZDateString } from './date';
import {
  IClinicalReading,
  ZClinicalReading,
  ZClinicalReadingInvalidated,
} from './child/clinicalReading';
import { ZChanges } from './child/changes';
import { ZSuverian } from './suverian';
import { ZEhrHistory } from './ehrStatus';

const ZIInteractionAuditChange = z.object({
  suverian: ZObjectId,
  datetime: ZDateString,
  changes: z.array(ZChanges),
});
export type IInteractionAuditChange = z.infer<typeof ZIInteractionAuditChange>;

const ZIInteractionAuditChangePopulated = ZIInteractionAuditChange.omit({
  suverian: true,
}).extend({
  suverian: ZSuverian,
});
export type IInteractionAuditChangePopulated = z.infer<
  typeof ZIInteractionAuditChangePopulated
>;

const ZCommonInteraction = z.object({
  _id: ZObjectId,
  airtable_id: z.string().optional(),
  patient: ZPatientId,
  datetime: z.date(),
  suverian: ZObjectId,
  note: z.string().optional(),
  interaction_type: ZInteractionType,
  structured_content: z.unknown().optional(),
  ehr_history: z.array(ZEhrHistory).default([]),
  clinical_readings: z.array(z.array(ZClinicalReading)).optional(),
  audit_changes: z.array(ZIInteractionAuditChange).default([]),
  invalidated_clinical_readings: z
    .array(ZClinicalReadingInvalidated)
    .optional(),
});

const ZPatientDetailsUpdatedInteraction = ZCommonInteraction.extend({
  interaction_type: z.literal('patient_details_updated'),
  structured_content: z.object({
    // TODO Should be more tightly defined to changes: IChanges<IPatient, keyof IPatient>[]
    changes: z.array(ZChanges),
  }),
});
export type IPatientDetailsUpdatedInteraction = z.infer<
  typeof ZPatientDetailsUpdatedInteraction
>;

const ZAlertCreatedInteraction = ZCommonInteraction.extend({
  interaction_type: z.literal('patient_alert_created'),
  structured_content: z.object({
    alert: ZAlert,
  }),
});
export type IAlertCreatedInteraction = z.infer<typeof ZAlertCreatedInteraction>;

const ZAutomatedSMSInteraction = ZCommonInteraction.extend({
  interaction_type: z.literal('type_sms_fetch_succesful'),
  structured_content: z.object({
    template_type: z.string().optional(),
    recipient: z.string().optional(),
  }),
});
export type IAutomatedSMSInteraction = z.infer<typeof ZAutomatedSMSInteraction>;

const ZClinicalQuestionnaireInteraction = ZCommonInteraction.extend({
  interaction_type: z.literal('clinician_questionnaire_completed'),
  clinical_readings: z.array(z.never()).length(0).optional(),
  structured_content: z.object({
    formType: ZClinicianCompletableQuestionnaireFormType,
  }),
});
export type IClinicalQuestionnaireInteraction = z.infer<
  typeof ZClinicalQuestionnaireInteraction
>;

const ZPatientQuestionnaireInteraction = ZCommonInteraction.extend({
  interaction_type: z.literal('patient_questionnaire_completed'),
  clinical_readings: z.array(z.never()).length(0).optional(),
  structured_content: z.object({
    formType: z.string(),
  }),
});
export type IPatientQuestionnaireInteraction = z.infer<
  typeof ZPatientQuestionnaireInteraction
>;

const ZCareTeamClinicalReadingSubmissionInteraction = ZCommonInteraction.extend(
  {
    interaction_type: z.enum([
      'clinician_email_clinical_submission',
      'clinician_phone_clinical_submission',
      'clinician_sms_clinical_submission',
      'clinician_other_clinical_submission',
    ]),
    structured_content: z.object({
      formType: z.string(),
    }),
  },
);
export type ICareTeamClinicalReadingSubmissionInteraction = z.infer<
  typeof ZCareTeamClinicalReadingSubmissionInteraction
>;

export const ZSmsOutgoingInteraction = ZCommonInteraction.extend({
  interaction_type: z.literal('sms_outgoing'),
  clinical_readings: z.array(z.never()).length(0).optional(),
  structured_content: z.object({
    recipient: z.string().optional(),
    template_type: z.string().optional(),
    template_version: z.number().optional(),
    sender_id: z.string().optional(),
    host_link: z.string().optional(),
  }),
});
export type ISmsOutgoingInteraction = z.infer<typeof ZSmsOutgoingInteraction>;

export const ZEmailOutgoingInteraction = ZCommonInteraction.extend({
  interaction_type: z.literal('email_outgoing'),
  clinical_readings: z.array(z.never()).length(0).optional(),
});
export type IEmailOutgoingInteraction = z.infer<
  typeof ZEmailOutgoingInteraction
>;

const ZOtherInteraction = ZCommonInteraction.extend({
  interaction_type: ZOtherInteractionType,
  structured_content: z.unknown(),
});
export type IOtherInteraction = z.infer<typeof ZOtherInteraction>;

const ZStructuredContentTags = z.object({
  resistant_hypertension: z.boolean().optional(),
  prescription_issued: z.boolean().optional(),
});

const ZConsultationInteraction = ZCommonInteraction.extend({
  interaction_type: ZConsultationInteractionType.exclude([
    'compound_consultation' as const,
  ]),
  clinical_readings: z.array(z.never()).length(0).optional(),
  structured_content: ZContextQuestions.extend({
    tags: ZStructuredContentTags.optional(),
  }),
});
export type IConsultationInteraction = z.infer<typeof ZConsultationInteraction>;

const ZCompoundConsultationInteraction = ZCommonInteraction.extend({
  interaction_type: z.literal('compound_consultation'),
  clinical_readings: z.array(z.never()).length(0).optional(),
  structured_content: ZTemplateStructuredContent.extend({
    tags: ZStructuredContentTags.optional(),
  }),
});
export type ICompoundConsultationInteraction = z.infer<
  typeof ZCompoundConsultationInteraction
>;

export const ZInteraction = z.union([
  ZPatientDetailsUpdatedInteraction,
  ZAlertCreatedInteraction,
  ZAutomatedSMSInteraction,
  ZClinicalQuestionnaireInteraction,
  ZPatientQuestionnaireInteraction,
  ZCareTeamClinicalReadingSubmissionInteraction,
  ZSmsOutgoingInteraction,
  ZEmailOutgoingInteraction,
  ZOtherInteraction,
  ZConsultationInteraction,
  ZCompoundConsultationInteraction,
]);
export type IInteraction = z.infer<typeof ZInteraction>;

function assertTypeEquality<T, U>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _equality: [T] extends [U] ? ([U] extends [T] ? true : false) : false,
) {}
assertTypeEquality<IInteraction['interaction_type'], EInteractionType>(true);

export interface NoIDNestedInteraction
  extends Omit<IInteraction, 'clinical_readings' | '_id'> {
  clinical_readings: Omit<IClinicalReading, '_id'>[][];
}
