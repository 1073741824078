export default function SuveraLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#0F1B38"
        d="M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16z"
      ></path>
      <path
        fill="#3D90FF"
        d="M14.385 9.688a3.817 3.817 0 107.633 0 3.817 3.817 0 00-7.633 0z"
      ></path>
      <path
        fill="#2FC96D"
        d="M15.789 16.295c3.016 3.878 3.304 7.582 3.314 10.08.003.73-.464 1.385-1.18 1.537-2.328.496-7.45.862-11.629-3.932-5.815-6.67-1.597-12.985-1.2-13.396 3.419 1.233 7.966 2.203 10.695 5.711z"
      ></path>
      <path
        fill="#BF79F3"
        d="M21.203 26.12c.21-1.939.028-3.44-.403-4.849-.904-2.956-.329-5.937 2.65-6.76l.047-.013c1.546-.427 3.178-.878 4.595-1.61.719 1.049.865 5.293-.606 8.202-1.442 2.852-3.846 4.73-5.522 5.603-.39.204-.809-.136-.761-.574z"
      ></path>
    </svg>
  );
}
