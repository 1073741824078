import { z } from 'zod';

const interactionTypesWithDiscriminatedIInteraction = [
  'patient_details_updated',
  'patient_alert_created',
] as const;

export const consultationInteractionTypes = [
  'routine_review',
  'urgent_review',
  'intro_call',
  'reengagement_call',
  'opt_out_call',
  'admin_note',
  'compound_consultation',
  'other',
] as const;
export const ZConsultationInteractionType = z.enum(
  consultationInteractionTypes,
);
export type EConsultationInteractionType = z.infer<
  typeof ZConsultationInteractionType
>;

export const patientDataSubmissionInteractionTypes = [
  'webform_1_clinical_submission',
  'webform_2_clinical_submission',
  'patient_questionnaire_completed' as const,
] as const;

export const careTeamDataSubmissionInteractionTypes = [
  'clinician_email_clinical_submission',
  'clinician_phone_clinical_submission',
  'clinician_sms_clinical_submission',
  'clinician_other_clinical_submission',
  'clinician_questionnaire_completed',
] as const;

export const allDataSubmissionInteractionTypes = [
  ...patientDataSubmissionInteractionTypes,
  ...careTeamDataSubmissionInteractionTypes,
] as const;

export const careTeamCommunicationInteractionTypes = [
  'sms_outgoing',
  'email_outgoing',
] as const;

export const otherInteractionTypes = [
  ...allDataSubmissionInteractionTypes,
  ...careTeamCommunicationInteractionTypes,
  'appointment',
  'intro_message_ehr',
  'type_sms_fetch_succesful',
  'webform_5_clinical_submission',
  'webform_clinical_submission',
  'clinician_consent_service',
] as const;
export const ZOtherInteractionType = z.enum(otherInteractionTypes);
export type OtherInteractionType = z.infer<typeof ZOtherInteractionType>;

export const allInteractionTypes = [
  ...interactionTypesWithDiscriminatedIInteraction,
  ...otherInteractionTypes,
  ...consultationInteractionTypes,
] as const;

export const ZInteractionType = z.enum(allInteractionTypes);
export type EInteractionType = z.infer<typeof ZInteractionType>;

export const interactionTypeIsOneOf = <T extends string, U extends string & T>(
  interactionType: T,
  types: readonly U[],
): interactionType is U => {
  return types.includes(interactionType as unknown as U);
};

export const ZIInteractionType = z.object({
  _id: ZInteractionType,
  name: z.string(),
});
export type IInteractionType = z.infer<typeof ZIInteractionType>;
