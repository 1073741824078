import { TabContext, TabList } from '@mui/lab';
import { Box, Divider, Stack, Tab, Typography } from '@mui/material';
import { IPartnerPatientDetails } from '@suvera/api-types';
import { format } from 'date-fns/fp';
import { useState } from 'react';

import { Avo } from 'infrastructure/analytics';
import { subTabStyles } from 'pages/PlannerListPage/components/PlannerList';
import { BoxWithGreyBorder } from 'components/FullScreenBoxWithGreyBorder';

const formatDateDDMMYYYY = format('dd/MM/yyyy');

type Props = {
  patient: IPartnerPatientDetails;
};

const PatientViewTargetTabs = ({ patient }: Props) => {
  const [selectedTab, setSelectedTab] = useState<
    'your-targets' | 'suvera-targets'
  >('your-targets');

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: 'your-targets' | 'suvera-targets',
  ) => {
    setSelectedTab(newValue);
    Avo.patientTargetsTabClicked({
      targetsTab: newValue,
    });
  };

  const getTargetAreasForSelectedTab = (isManagedBySuvera: boolean) =>
    (isManagedBySuvera && selectedTab === 'suvera-targets') ||
    (!isManagedBySuvera && selectedTab === 'your-targets');

  const shouldShowNoTargetsCopyText =
    patient.targetData.length === 0 ||
    patient.targetData.filter((d) =>
      getTargetAreasForSelectedTab(d.isManagedBySuvera),
    ).length === 0;

  return (
    <Box flex={2}>
      <BoxWithGreyBorder>
        <Stack margin="-16px">
          <Typography variant="h3" component="h2" sx={{ padding: '16px' }}>
            Targets
          </Typography>

          <TabContext value={selectedTab}>
            <TabList
              onChange={handleChange}
              aria-label="Targets tabs"
              sx={{
                minHeight: '40px',
                width: '100%',
                paddingX: '16px',
              }}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: 'colors.blue',
                  height: '4px',
                  '&.MuiTabs-indicator': {
                    height: '2px',
                  },
                },
              }}
            >
              <Tab
                label="Your targets"
                sx={{
                  ...subTabStyles,
                  width: '50%',
                  marginX: '0px',
                  maxWidth: 'unset',
                }}
                value="your-targets"
              />
              <Tab
                label="Suvera's targets"
                sx={{
                  ...subTabStyles,
                  width: '50%',
                  marginX: '0px',
                  maxWidth: 'unset',
                }}
                value="suvera-targets"
              />
            </TabList>
          </TabContext>

          <Divider />

          <Stack
            spacing="16px"
            sx={{
              overflowY: 'auto',
              height: 'calc(100vh - 270px)',
              ...(shouldShowNoTargetsCopyText
                ? { justifyContent: 'center' }
                : {}),
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
            }}
          >
            {shouldShowNoTargetsCopyText && (
              <Stack sx={{ textAlign: 'center', justifyContent: 'center' }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '18px',
                  }}
                >
                  No targets
                </Typography>
                <Typography sx={{ color: 'colors.greyDark' }}>
                  {`This patient has no targets managed by ${selectedTab === 'your-targets' ? 'practice' : 'Suvera'}`}
                </Typography>
              </Stack>
            )}

            {patient.targetData.length > 0 &&
              patient.targetData
                .filter((target) =>
                  selectedTab === 'your-targets'
                    ? !target.isManagedBySuvera
                    : target.isManagedBySuvera,
                )
                .map((target) => (
                  <Box
                    key={target.label}
                    sx={{
                      backgroundColor: 'colors.greyLightest',
                      padding: '0 12px',
                      marginTop: '0px !important',
                    }}
                  >
                    <Box sx={{ padding: '12px 0' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
                          {target.label}
                        </Typography>
                        <Typography variant="body2">{target.state}</Typography>
                      </Box>
                      <Typography variant="body2">{`Last updated ${target.lastUpdated ? formatDateDDMMYYYY(target.lastUpdated) : '-'}`}</Typography>
                    </Box>
                    {target.targets.length === 0 && (
                      <Box
                        sx={{
                          backgroundColor: 'colors.white',
                          marginX: '-12px',
                          padding: '12px',
                          textAlign: 'center',
                          borderBottom: '1px solid',
                          borderBottomColor: 'colors.greyLight',
                        }}
                      >
                        <Typography variant="body1" color="colors.greyDark">
                          {`This patient has no ${target.label.toLocaleLowerCase()} targets managed by ${selectedTab === 'your-targets' ? 'practice' : 'Suvera'}`}
                        </Typography>
                      </Box>
                    )}
                    {target.targets.length > 0 &&
                      target.targets.map((t) => (
                        <Box
                          key={t.target}
                          sx={{
                            backgroundColor: 'colors.white',
                            marginX: '-12px',
                            padding: '8px 12px',
                            borderBottom: '1px solid',
                            borderBottomColor: 'colors.greyLight',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography variant="body2" color="colors.greyDark">
                              {t.target}
                            </Typography>
                            <Typography variant="body2" color="colors.greyDark">
                              {t.state}
                            </Typography>
                          </Box>
                          <Typography variant="body2">
                            {t.description}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                ))}
          </Stack>
        </Stack>
      </BoxWithGreyBorder>
    </Box>
  );
};

export default PatientViewTargetTabs;
