import { z } from 'zod';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OmitBetterStrict<T, K extends keyof T> = T extends any
  ? Pick<T, Exclude<keyof T, K>>
  : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NoID<T extends { _id: any }> = OmitBetterStrict<T, '_id'>;

export function safeTypedID<T extends string>(item: { _id: T } | T): T {
  if (typeof item === 'string') {
    return item;
  }
  return item._id;
}

type NotNull<T> = T extends null | undefined ? never : T;
// eslint-disable-next-line @typescript-eslint/ban-types
type Primitive = undefined | null | boolean | string | number | Function;
export type DeepRequired<T> = T extends Primitive
  ? NotNull<T>
  : {
      [P in keyof T]-?: T[P] extends Array<infer U>
        ? Array<DeepRequired<U>>
        : T[P] extends ReadonlyArray<infer U2>
          ? DeepRequired<U2>
          : DeepRequired<T[P]>;
    };

export const ZObjectNoExplicitlyUndefinedOrNullValues = z
  .object({})
  .passthrough()
  .refine(
    (patch) => {
      const keys = Object.keys(patch) as (keyof typeof patch)[];
      const keyWithUndefinedOrNullValue = keys.find(
        (key) => patch[key] === undefined || patch[key] === null,
      );
      if (keyWithUndefinedOrNullValue) return false; // Error
      return true;
    },
    { message: 'Attempted to pass null or undefined for a key' },
  );
