import { useGetAuthenticatedPartner } from 'App/PartnerAuth/requests';

export const usePartnerName = () => {
  const { data } = useGetAuthenticatedPartner();

  if (!data) return { avatarText: '', partnerName: '' };

  const partnerName =
    data.name && Object.keys(data.name).length > 0
      ? `${data.name.first} ${data.name.last}`
      : data.practice.name;

  const avatarText = `${partnerName.split(' ')[0][0]}${partnerName.split(' ')[1][0]}`;

  return { avatarText, partnerName };
};
